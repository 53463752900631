import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzDialog,
  BirdzTitle,
  SelectField,
  useDialog
} from '@applications-terrains/birdz-react-library';

import { Box, Button, CircularProgress, Grid, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { SendingType } from '../../../Extractor/Jobs/SendingType';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { isArray } from 'lodash';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';

type Export = {
  client?: number | null;
  export_type?: 'SFTP' | 'MANUAL' | 'INTERNAL';
  format: 'CSV' | string;
  sending_credentials?: { password: string | null; private_key: string | null };
};

const ExportDetail = () => {
  const { id } = useParams();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const navigate = useNavigate();

  const [currentExport, setCurrentExport] = useState<Export>({
    client: undefined,
    format: 'CSV',
    export_type: undefined
  });
  const [validationSchema, setValidationSchema] = useState<Yup.ObjectShape>({
    client: Yup.number().required('Champ obligatoire'),
    format: Yup.string().required('Champ obligatoire'),
    export_type: Yup.string().required('Champ obligatoire')
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [clients, setClients] = useState<{ id: number; name: string }[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const endpoint = `/api/boi/breader/exports/`;

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios.get(`${endpoint}/${id}`).then((res) => {
        setLoading(false);
        setCurrentExport(res.data);
      });
    }
  }, [id, endpoint]);

  useEffect(() => {
    clients.length === 0 &&
      axios.get(`/api/boi/breader/clients/?size=9999`).then((res) => {
        setClients(res.data.results);
      });
  }, [clients]);

  const handleClose = (status?: RequestStatus) => {
    closeDialog();
    setLoading(false);
    setSubmitting(false);
    setRequestStatus(status || null);
  };

  const handleSubmit = (values: Export) => {
    setRequestStatus('pending');
    const formattedExport: Export = {
      client: values.client,
      export_type: values.export_type,
      format: values.format
    };
    if (values.export_type === 'SFTP' && values.sending_credentials) {
      const formattedSendingCredentials = {
        ...values.sending_credentials,
        password: values.sending_credentials['password'] || null,
        private_key: values.sending_credentials['private_key'] || null
      };
      formattedExport['sending_credentials'] = formattedSendingCredentials;
    }
    setSubmitting(true);
    const method = id ? 'put' : 'post';
    const url = `${endpoint}${id ? '/' + id : ''}`;
    axios[method](url, formattedExport)
      .then(() => {
        handleClose('success');
      })
      .catch((err) => {
        handleClose('error');
        const errorMessage =
          'client' in err.response.data &&
          isArray(err.response.data['client']) &&
          err.response.data['client'][0] === 'this client already has an export'
            ? 'Un export existe déjà pour ce client'
            : `Une erreur est survenue lors de la ${id ? 'modification' : 'création'} de l'export`;
        setErrorMessage(errorMessage);
      });
  };

  return (
    <AppPaper>
      <BirdzTitle>{!id ? 'Ajouter un export' : "Détail de l'export"}</BirdzTitle>
      {loading ? (
        <Skeleton height={400} variant="rounded" />
      ) : (
        <AppPaper>
          <Box sx={{ mt: 3 }}>
            <Formik
              validationSchema={Yup.object(validationSchema)}
              initialValues={currentExport}
              onSubmit={(values) => {
                confirmDialog({
                  title: `Confirmer ${id ? 'les modifications' : 'la création'}`,
                  content: 'Souhaitez-vous confirmer vos saisies ?',
                  onValidate: () => handleSubmit(values),
                  onCancel: () => {
                    handleClose();
                  },
                  okButtonLabel: 'Oui',
                  cancelButtonLabel: 'Non'
                });
              }}
            >
              {({ values, isValid, dirty }) => {
                return (
                  <Form>
                    <Grid container alignItems={'center'} sx={{ my: 2, p: 0, gap: '1rem 0' }}>
                      <Grid item xs={1.49}>
                        {'Client'}
                      </Grid>
                      <Grid item xs={10.51}>
                        <SelectField
                          name="client"
                          options={clients.map((el) => ({ label: el.name, value: el.id }))}
                        />
                      </Grid>
                      <Grid item xs={1.49}>
                        {'Format'}
                      </Grid>
                      <Grid item xs={10.51}>
                        <SelectField name="format" options={[{ label: 'CSV', value: 'CSV' }]} />
                      </Grid>
                      <Box className="d-flex w-100">
                        <Grid item xs={1.49}>
                          <Box style={{ height: '40px' }} alignItems="center" className="d-flex">
                            {"Type d'envoi"}
                          </Box>
                        </Grid>
                        <Grid item xs={10.51}>
                          <SelectField
                            name="export_type"
                            options={[
                              { label: 'sFTP', value: 'SFTP' },
                              { label: 'Manuel (back-office régie)', value: 'MANUAL' },
                              { label: 'Interne Birdz (bucket webconsole)', value: 'INTERNAL' }
                            ]}
                          />
                          {values && values?.export_type === 'SFTP' && (
                            <Box sx={{ mt: 2 }}>
                              <SendingType
                                setValidationSchema={setValidationSchema}
                                validationSchema={validationSchema}
                                objKey={'sending_credentials'}
                                targetTab={'SFTP'}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Box>
                    </Grid>

                    <Box sx={{ textAlign: 'center' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        // form="topForm"
                        sx={{ m: 2 }}
                        disabled={!dirty || !isValid || submitting}
                      >
                        {submitting && <CircularProgress size={18} thickness={5} sx={{ mr: 1 }} />}{' '}
                        Enregistrer
                      </Button>

                      <Button
                        className="btn-space"
                        onClick={() => {
                          confirmDialog({
                            title: 'Annuler les saisies',
                            content: 'Souhaitez-vous annuler vos saisies ?',
                            onValidate: () => {
                              handleClose();
                              navigate(-1);
                            },
                            onCancel: () => {
                              handleClose();
                            },
                            okButtonLabel: 'Oui',
                            cancelButtonLabel: 'Non'
                          });
                        }}
                      >
                        Annuler
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </AppPaper>
      )}
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          handleClose();
        }}
        action="Export"
        status={requestStatus}
        setStatus={setRequestStatus}
        customErrorMessage={errorMessage}
        timeout={3000}
        onSuccess={() => navigate(-1)}
      />
    </AppPaper>
  );
};

export default ExportDetail;

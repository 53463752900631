import React, { Fragment, useState } from 'react';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import './dndTable.css';
import { Box } from '@mui/material';
import { Action, ListField } from '@applications-terrains/birdz-react-library';
import { ActionType, ExportFormatSettingsType } from '../ExportFormatSettings';
import { isEqual } from 'lodash';
import axios from 'axios';
import { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';

const ExportFormatSettingsTable = ({
  data,
  fields,
  actions,
  onUpdate
}: {
  data: ExportFormatSettingsType[];
  fields: ListField[];
  actions: Action[];
  onUpdate: (values: { action: ActionType; status: RequestStatus }) => void;
}) => {
  const [isDragging, setIsDragging] = useState<number | null>(null);
  const [ghostIndex, setGhostIndex] = useState<number | null>(null);

  const [parent, children] = useDragAndDrop<
    HTMLTableSectionElement,
    { export_field_name: string; id: number; [key: string]: any }
  >(data, {
    dragHandle: '.handle',
    onDragstart: (el) => {
      setIsDragging((el.draggedNode.data.value as { id: number }).id);
    },
    onDragend: (state: any) => {
      const indexes = data.map((el: ExportFormatSettingsType) => ({
        id: el.id,
        index: el.index
      }));
      const newIndexes = (state.values || []).map(
        (el: ExportFormatSettingsType, index: number) => ({
          id: el.id,
          index
        })
      );
      const action = 'Déplacement de la colonne';
      if (indexes.length === newIndexes.length && !isEqual(indexes, newIndexes)) {
        onUpdate({
          action,
          status: 'pending'
        });
        axios
          .put(`/api/boi/extractor/export-format-settings/index-sorting/`, newIndexes)
          .then(() => {
            onUpdate({
              action,
              status: 'success'
            });
          })
          .catch(() => {
            onUpdate({
              action,
              status: 'error'
            });
          });
      }

      setIsDragging(null);
    },
    scrollBehavior: {
      x: 1,
      y: 0.9,
      scrollOutside: true
    },
    plugins: [animations()]
  });

  return (
    <Paper
      sx={{
        border: '1px solid rgba(224, 224, 224, 1)',
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: 'fit-content',
        p: 3
      }}
      className="d-flex flex-grow-1"
      elevation={0}
    >
      <TableContainer>
        <Table size="small" aria-label="dnd table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding={'checkbox'}></TableCell>
              {fields.map((el) => (
                <TableCell className="header-cell" key={el.label + 'head'}>
                  {el.label}
                </TableCell>
              ))}
              {actions.length > 0 && <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody ref={parent} sx={{ overflow: 'scroll' }}>
            {children.map((row, index) => {
              //keep track of ghost element position (used in blur filter)
              if (isDragging === row.id && ghostIndex !== index) setGhostIndex(index);
              else if (!isDragging && ghostIndex) setGhostIndex(null);

              return (
                <TableRow
                  data-label={row.export_field_name}
                  key={'tableRow' + row.export_field_name + index}
                  className={`${isDragging === row.id ? 'dragged-row' : isDragging ? 'table-row not-dragging' : 'table-row'}`}
                  sx={{
                    cursor: isDragging ? 'grabbing' : 'default',
                    //apply progressive blur based on index of ghost element
                    filter: `blur(${ghostIndex && index !== ghostIndex ? Math.abs(index - ghostIndex) / 2 : 0}px)`
                  }}
                >
                  <TableCell padding={'checkbox'}>
                    <DragHandleIcon className={`handle`} sx={{ cursor: 'grab' }} />
                  </TableCell>
                  {fields.map((el) => {
                    const transform = el?.transform ? el?.transform('', row) : '';
                    return (
                      <TableCell key={'tableRow' + el.label + 'Cell' + index} className="cell">
                        <Box className="inner-cell">{transform || row[el.name] || ''}</Box>
                      </TableCell>
                    );
                  })}
                  {actions.length > 0 && (
                    <TableCell width={'1px'}>
                      <Box className="d-flex" sx={{ textAlign: 'right', marginRight: '-16px' }}>
                        {actions.map(
                          (action) =>
                            typeof action.render === 'function' && (
                              <Fragment key={action.name}>{action?.render(row)}</Fragment>
                            )
                        )}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ExportFormatSettingsTable;

import React, { useState } from 'react';
import { useTourFormContext } from '../context/tourFormContext';
import ClearableSelect from '../../../../../Tools/ClearableSelect';
import { Button, Grid } from '@mui/material';
import { TourDevice } from '../types';
import { AppPaper } from '@applications-terrains/birdz-react-library';

const TourListing = () => {
  const [city, setCity] = useState<string>();
  const { tourData, setSelectedDevices, setRefreshListPage, refreshDevicesStep } =
    useTourFormContext();
  const devices = tourData.patrimonyStep;

  const getCities = (devices: TourDevice[]) => {
    const singleCities: string[] = [];
    devices.forEach((device) => {
      const city = device.COMMUNE_ABO;
      if (!singleCities.includes(city)) singleCities.push(city);
    });
    return singleCities;
  };

  const getStreets = (devices: TourDevice[]) => {
    const singleStreets: string[] = [];
    devices
      .filter((device) => device.COMMUNE_ABO === city)
      .forEach((device) => {
        const street = device.RUE_ABO;
        if (!singleStreets.includes(street)) singleStreets.push(street);
      });
    return singleStreets;
  };

  return (
    <>
      {devices && !refreshDevicesStep && (
        <AppPaper sx={{ pt: 2 }}>
          <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
            <Grid item xs={2}>
              COMMUNE
            </Grid>
            <Grid item xs={10}>
              <ClearableSelect
                options={getCities(devices)
                  .map((city) => ({
                    label: city,
                    value: city
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(city) => {
                  city && setCity(city.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
            <Grid item xs={2}>
              RUE
            </Grid>
            <Grid item xs={10}>
              <ClearableSelect
                options={getStreets(devices)
                  .map((street) => ({
                    label: street,
                    value: street
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(street) => {
                  street &&
                    setSelectedDevices(devices.filter((device) => device.RUE_ABO === street.value));
                  setRefreshListPage(true);
                }}
                disabled={!city}
              />
            </Grid>
          </Grid>
          <Grid container className="d-flex" justifyContent={'end'}>
            <Button
              disabled={devices.length === 0}
              id="reinitialize"
              onClick={() => setCity(undefined)}
            >
              Reinitialiser
            </Button>
          </Grid>
        </AppPaper>
      )}
    </>
  );
};

export default TourListing;

import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Home.scss';
import { Message } from '../../types';
import Interventions from '../CR/Interventions/Interventions';
import { authService } from '../..';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authService.canAccessBReaderBOE()) {
      setTimeout(() => {
        navigate('/breader/tours');
      }, 0); //setTimeout is only here to ensure navigate() is called after full mount
    }
  }, [navigate, authService]);

  useEffect(() => {
    axios
      .get(authService.getEndpoint('/api/boi/home-messages/', '/api/boe/home-messages/'))
      .then((response: any) => {
        const data = response.data;
        if (data.results) {
          data.results.map((result: any) => {
            if (!result.id && result.sysid) {
              result.id = result.sysid;
            }
            return result;
          });

          setMessages(data.results);
        }
      });
  }, []);

  return (
    <>
      {messages.map((message) => (
        <Card key={message.id} sx={{ mb: 2 }} variant="outlined">
          <CardHeader
            title={message.title}
            subheader={moment.unix(message.created_at).format('DD/MM/YYYY')}
          />
          <CardContent>
            <Typography dangerouslySetInnerHTML={{ __html: message.content }} />
          </CardContent>
        </Card>
      ))}

      {authService.canAccessBOE() && <Interventions displayFilters={false} />}
    </>
  );
};

export default Home;

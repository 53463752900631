import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  BirdzModal,
  BirdzModalTitle,
  BirdzModalContent,
  BirdzModalActions
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopy from '@mui/icons-material/ContentCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Job } from './JobForm';
import moment from 'moment';
import ToggleActiveOrInactive from './ToggleActiveOrInactive';
import JobSearchForm from './JobSearchForm';
import { getERPNameAndVersion } from '../Erps/utils';
import { Erp } from '../Erps/ErpForm';
import PreviewJobHandler from './PreviewJobHandler';

type JobListItem = {
  id: number;
  name: string;
  active: boolean;
  client: number;
  erp: number;
  module_families: string[];
  intervention_types: string[];
  programming_type: string;
  cron: string;
  start_at: string;
  erp_name: string;
  erp_version: string;
  credentials_sending_export: {
    id: number;
    sending_type: string;
    server: string;
    user: string;
    password: string;
    private_key: string | null;
  };
};

const Jobs = () => {
  const endpoint = '/api/boi/extractor/jobs/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();

  const [showExecuteJobIdModal, setShowExecuteJobIdModal] = useState<number>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');

  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  const listFields = [
    { name: 'name', label: 'Nom' },
    { name: 'client_name', label: 'Client' },
    { name: 'export_format_name', label: 'Format' },
    { name: 'module_families', label: 'Famille de module' },
    {
      name: 'erp_name',
      label: 'ERP',
      orderable: true,
      transform: (_: string, job: JobListItem) => {
        return getERPNameAndVersion({
          name: job.erp_name,
          version: job.erp_version
        } as Erp);
      }
    },
    {
      name: 'last_execution',
      label: 'Dernière execution',
      transform: (date: string) => {
        return (date && moment(date).format('DD/MM/YYYY à HH:mm:ss')) || '-';
      }
    },
    {
      name: 'next_execution',
      label: 'Prochaine execution',
      transform: (date: string) => {
        return (date && moment(date).format('DD/MM/YYYY à HH:mm:ss')) || '-';
      }
    }
  ];

  const actions = [
    {
      name: 'execute',
      label: 'Exécuter le job',
      render: (job: JobListItem) => {
        return (
          <IconButton
            onClick={() => {
              setShowExecuteJobIdModal(job.id);
            }}
          >
            <PlayArrowIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'duplicate',
      label: 'Dupliquer le job',
      render: (job: JobListItem) => {
        return (
          <IconButton
            onClick={async () => {
              const response: AxiosResponse<Job> = await axios.get(`${endpoint}${job.id}/`);
              const newJob: Partial<Job> = response.data;
              newJob.name = `${newJob.name} (copie)`;
              delete newJob.id;
              axios.post(endpoint, newJob).then(() => {
                notif({
                  content: 'Le job a été dupliqué avec succès',
                  type: 'success'
                });
              });
              setTimeout(() => {
                navigate(0);
              }, 2000);
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'edit',
      label: 'Modifier le job',
      render: (exportFormat: any) => {
        return (
          <IconButton component={Link} to={`/boi/extractor/jobs/edit/${exportFormat.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'toggle',
      label: 'Activer/désactiver',
      render: (job: Job) => {
        return <ToggleActiveOrInactive job={job} setRefresh={setRefresh} />;
      }
    },
    {
      name: 'preview',
      label: 'Prévisualiser le job',
      render: (job: Job) => {
        return <PreviewJobHandler jobId={job.id} />;
      }
    },
    {
      name: 'delete-job',
      label: 'Supprimer le job',
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le job',
                content: 'Êtes-vous sûr de vouloir supprimer ce job?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'Le job a été supprimé avec succès',
                        type: 'success'
                      });
                      setTimeout(() => {
                        navigate(0);
                      }, 2000);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des jobs</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/extractor/jobs/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Créer un job
            </Button>
          </Link>
        </Grid>
      </Grid>

      <JobSearchForm setFilterString={setFilterString} endpoint={endpoint} />

      {!refresh && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          filters={filterString}
        />
      )}
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
      {showExecuteJobIdModal && (
        <ExecuteJobModal
          onValidate={(reason: string) => {
            axios
              .post(`/api/boi/extractor/jobs/${showExecuteJobIdModal}/run-job/`, {
                raison: reason
              })
              .then(
                () => {
                  notif({
                    type: 'success',
                    content: 'Le job a été lancé avec succès'
                  });
                },
                () => {
                  notif({
                    type: 'error',
                    content: 'Une erreur est survenue lors du lancement du job'
                  });
                }
              );
            setShowExecuteJobIdModal(undefined);
          }}
          onCancel={() => {
            setShowExecuteJobIdModal(undefined);
          }}
        />
      )}
    </AppPaper>
  );
};

export default Jobs;

const ExecuteJobModal = ({
  onValidate,
  onCancel
}: {
  onValidate: (motif: string) => void;
  onCancel: () => void;
}) => {
  const [motif, setMotif] = useState<string>('');

  return (
    <>
      <BirdzModal
        open={true}
        onClose={() => {
          onCancel();
        }}
        maxWidth={'sm'}
      >
        <BirdzModalTitle>Exécuter un job</BirdzModalTitle>
        <BirdzModalContent>
          <Box sx={{ display: 'flex' }} alignItems={'center'}>
            <Box sx={{ m: 3 }}>Motif</Box>

            <TextField
              name="motif"
              multiline
              rows={4}
              type="textarea"
              variant="outlined"
              sx={{
                m: 1,
                width: '100%'
              }}
              onChange={(e) => {
                setMotif(e.target.value);
              }}
              value={motif}
            />
          </Box>
        </BirdzModalContent>
        <BirdzModalActions>
          <Button variant="outlined" onClick={() => onCancel()}>
            Annuler
          </Button>
          <Button
            onClick={() => {
              onValidate(motif);
            }}
            disabled={!motif}
            variant="contained"
          >
            Lancer
          </Button>
        </BirdzModalActions>
      </BirdzModal>
    </>
  );
};

import { BirdzDialog, BirdzTitle, useDialog } from '@applications-terrains/birdz-react-library';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExportFormat } from './ExportFormatForm';
import Edit from '@mui/icons-material/Edit';
import { useGetExtractorFields } from '../../../hooks/datarefs';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircle from '@mui/icons-material/AddCircle';
import _ from 'lodash';
import Delete from '@mui/icons-material/Delete';
import { ExportFormatBase } from './ExportFormatSettingsModal/ExportFormatBase';
import TitleIcon from '@mui/icons-material/Title';
import TodayIcon from '@mui/icons-material/Today';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Filter1Icon from '@mui/icons-material/Filter1';
import { useGlobalContext } from '../../../contexts/globalContext';
import DataMappingHandler from './DataMappingHandler';
import ExportFormatSettingsTable from './Table/ExportFormatSettingsTable';
import { useFetchContext } from '../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../../Tools/ModalWithLoader/ModalWithLoader';
import { getERPNameAndVersion } from '../Erps/utils';
import { Erp } from '../Erps/ErpForm';

export type ExtractorFieldsGroupedBySource = {
  [source: string]: { id: number; name: string; type: string; source: string };
};

export type ExportField = {
  id: number;
  name: string;
  type: string;
  source: string;
};

export type ExportFormatSettingsType = {
  id: number;
  index: number;
  export_field_name: string;
  export_format: number;
  data_mapping: number;
  field_to_export: number;
  field_to_export_object: ExportField;
  fixed_label: string;
  bool_if_true: boolean;
  bool_if_false: boolean;
  bool_if_null: boolean;
  text_prefix: string;
  text_suffix: string;
  number_decimal_separator: number;
  number_thousand_separator: number;
  number_digit_number_after_comma: number;
  date_forced_time: string;
  date_operation_type: string;
  date_operation_count: number;
  date_operation_unit: string;
  date_format: string;
  created_at: number;
  updated_at: number;
};

export type ActionType = 'Création' | 'Suppression' | 'Modification' | string | null;

export default function ExportFormatSettings() {
  const { id } = useParams<{ id: string }>();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { setValues } = useGlobalContext();

  if (!id) {
    return null;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<Partial<ExportFormat>>({});
  const endpoint = '/api/boi/extractor/export-formats/';
  const { data: extractorFields } = useGetExtractorFields();
  const [columnToAdd, setColumnToAdd] = useState<ExportField>();
  const [exportformatSettings, setExportFormatSettings] =
    useState<Partial<ExportFormatSettingsType>>();
  const [expandedAccordion, setExpandedAccordion] = React.useState<string | false>(false);
  const { needToFetch, toggleNeedToFetch } = useFetchContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [action, setAction] = useState<ActionType>(null);

  // exportFormat.settings
  const loadExportFormatSettings = useCallback(() => {
    if (id) {
      setIsLoading(true);
      axios.get(`${endpoint}${id}/`).then((response: AxiosResponse<ExportFormat>) => {
        setExportFormat(response.data);
        setValues(response.data);
        setIsLoading(false);
        toggleNeedToFetch(false);
      });
    }
  }, [id]);

  useEffect(() => {
    loadExportFormatSettings();
  }, [id, needToFetch]);

  const getExtractorFields = useCallback(() => {
    return _.groupBy(extractorFields, 'source');
  }, [extractorFields]);

  const getExtractorFieldById = useCallback(
    (fieldId: number) => {
      return extractorFields?.find((field: any) => field.id === fieldId);
    },
    [extractorFields]
  );

  const onExpandAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const addColumn = (formValues: Partial<ExportFormatSettingsType>) => {
    const payload = Object.assign({}, formValues, { export_format: parseInt(id) });
    setRequestStatus('pending');
    setIsLoading(true);
    if (!formValues.id) {
      setAction('Création');
      const indexMax = Math.max(...(exportFormat.settings?.map((el) => el.index) || []));
      return axios
        .post(`/api/boi/extractor/export-format-settings/`, {
          ...payload,
          index: indexMax !== -Infinity ? indexMax + 1 : 0
        })
        .then(() => {
          setRequestStatus('success');
        });
    } else {
      setAction('Modification');
      return axios
        .put(`/api/boi/extractor/export-format-settings/${payload.id}/`, payload)
        .then(() => {
          setRequestStatus('success');
        });
    }
  };

  const extractorFieldsGroupedBySource = getExtractorFields();

  const SettingsTable = () => (
    <ExportFormatSettingsTable
      onUpdate={({ status, action }) => {
        setRequestStatus(status);
        setAction(action);
        setIsLoading(true);
      }}
      data={exportFormat.settings?.sort((a, b) => a.index - b.index) || []}
      fields={[
        {
          name: 'column_name',
          label: 'Champ',
          transform: (_, setting: any) => {
            const field = getExtractorFieldById(setting.field_to_export);
            return `${field?.source} - ${field?.name}`;
          }
        },
        {
          name: 'field_type',
          label: 'Type',
          transform: (_, setting: any) => {
            const field = getExtractorFieldById(setting.field_to_export);
            return `${field?.type || '-'}`;
          }
        },
        {
          name: 'date_format',
          label: 'Format',
          transform: (_, row) => {
            return row.date_format || '-';
          }
        },
        {
          name: 'mapping',
          label: 'Correspondance',
          transform: (_, setting: { data_mapping: number | null }) => {
            return <DataMappingHandler data_mapping={setting.data_mapping} />;
          }
        },
        {
          name: 'export_field_name',
          label: 'Nom de la colonne'
        }
      ]}
      actions={[
        {
          name: 'edit',
          render: (exportFormatSettings: ExportFormatSettingsType) => {
            return (
              <IconButton
                onClick={() => {
                  setExportFormatSettings(exportFormatSettings);
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
            );
          }
        },
        {
          name: 'delete',
          render: (exportFormatSettings: ExportFormatSettingsType) => {
            return (
              <IconButton
                onClick={() => {
                  confirmDialog({
                    title: 'Supprimer la colonne',
                    content: 'Êtes-vous sûr de vouloir supprimer cette colonne?',
                    onValidate: () => {
                      setAction('Suppression');
                      setRequestStatus('pending');
                      setIsLoading(true);
                      axios
                        .delete(
                          `/api/boi/extractor/export-format-settings/${exportFormatSettings.id}/`
                        )
                        .then(() => {
                          setRequestStatus('success');
                        });
                    },
                    onCancel: () => {
                      closeDialog();
                      setRequestStatus(null);
                      setAction(null);
                    }
                  });
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            );
          }
        }
      ]}
    />
  );

  return (
    <>
      <Paper variant="outlined" sx={{ px: 2, pb: 2 }}>
        <BirdzTitle>Paramétrage d'un format d'export</BirdzTitle>
        <Paper
          sx={{
            border: '1px solid rgba(224, 224, 224, 1)',
            p: 2,
            mb: 2
          }}
          elevation={0}
        >
          <Grid container alignItems="center" spacing={2} sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            <Grid alignItems={'center'} item xs={2} sx={{ fontWeight: '500' }}>
              Nom
            </Grid>
            <Grid alignItems={'center'} item xs={4} sx={{ fontWeight: '300' }}>
              {exportFormat.name}
            </Grid>
            <Grid alignItems={'center'} item xs={2} sx={{ fontWeight: '500' }}>
              ERP
            </Grid>
            <Grid alignItems={'center'} item xs={4} sx={{ fontWeight: '300' }}>
              {getERPNameAndVersion({
                name: exportFormat.erp_name,
                version: exportFormat.erp_version
              } as Erp)}
            </Grid>
            <Grid alignItems={'center'} item xs={2} sx={{ fontWeight: '500' }}>
              Version
            </Grid>
            <Grid alignItems={'center'} item xs={4} sx={{ fontWeight: '300' }}>
              {exportFormat.version}
            </Grid>
            <Grid alignItems={'center'} item xs={2} sx={{ fontWeight: '500' }}>
              Type de fichier
            </Grid>
            <Grid alignItems={'center'} item xs={4} sx={{ fontWeight: '300' }}>
              {exportFormat.file_type}
            </Grid>
          </Grid>
        </Paper>

        <Grid container spacing={1} className="d-flex">
          <Grid item xs={4}>
            {extractorFieldsGroupedBySource &&
              Object.keys(extractorFieldsGroupedBySource).map((source) => {
                const extractorFields = extractorFieldsGroupedBySource[source]
                  ? extractorFieldsGroupedBySource[source]
                  : [];

                return (
                  <Accordion
                    key={source}
                    disableGutters
                    onChange={onExpandAccordion(source)}
                    sx={{
                      mb: 1,
                      borderRadius: '4px',
                      border: '1px solid rgba(224, 224, 224, 1)',
                      backgroundColor:
                        expandedAccordion === source ? 'rgba(224, 224, 224, 0.2)' : 'inherit',
                      '::before': {
                        display: 'none'
                      }
                    }}
                    elevation={0}
                    expanded={expandedAccordion === source}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      id="bconnect-fields"
                      sx={{
                        height: '34px',
                        minHeight: '34px',
                        border: 'none'
                      }}
                    >
                      {source}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flex: 1, backgroundColor: 'white', borderRadius: '4px' }}>
                          <Autocomplete
                            onChange={(e: any, newValues: any) => {
                              if (!newValues) return;

                              setColumnToAdd({
                                name: newValues.value,
                                type: newValues.type,
                                id: newValues.id,
                                source: newValues.source
                              });
                            }}
                            options={extractorFields.map((extractorField: any) => {
                              return {
                                value: extractorField.name,
                                label: extractorField.name,
                                type: extractorField.type,
                                id: extractorField.id
                              };
                            })}
                            renderInput={(params: any) => {
                              return <TextField {...params} variant="outlined" size="small" />;
                            }}
                            renderOption={(props, option) => {
                              let IconComponent;
                              switch (option.type) {
                                case 'TEXT':
                                  IconComponent = TitleIcon;
                                  break;
                                case 'DATE':
                                  IconComponent = TodayIcon;
                                  break;
                                case 'BOOLEAN':
                                  IconComponent = LibraryAddCheckIcon;
                                  break;
                                case 'NUMBER':
                                  IconComponent = Filter1Icon;
                                  break;
                              }
                              return (
                                <li {...props}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%'
                                    }}
                                  >
                                    {option.label}
                                    {IconComponent && <IconComponent />}
                                  </div>
                                </li>
                              );
                            }}
                            noOptionsText="Aucune valeur à sélectionner"
                            loadingText="Chargement en cours..."
                          />
                        </Box>
                        <IconButton
                          disabled={!columnToAdd}
                          onClick={() => {
                            setColumnToAdd(undefined);
                            if (columnToAdd) {
                              setExportFormatSettings({
                                field_to_export_object: columnToAdd,
                                field_to_export: columnToAdd.id
                              });
                            }
                          }}
                        >
                          <AddCircle fontSize="small" />
                        </IconButton>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Grid>

          <Grid
            xs={8}
            className="d-flex flex-column"
            sx={{
              pt: 1,
              pl: 2,
              overflow: 'hidden',
              height: 'calc(100vh - 23rem)'
            }}
          >
            {isLoading ? (
              <Paper
                sx={{
                  height: '100%',
                  position: 'relative',
                  border: '1px solid rgba(224, 224, 224, 1)'
                }}
                elevation={0}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    filter: 'blur(4px)',
                    width: '100%',
                    height: '100%',
                    transition: 'filter 0.5s ease'
                  }}
                >
                  {React.cloneElement(<SettingsTable />)}
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <CircularProgress
                    sx={{
                      opacity: requestStatus === null ? 1 : 0,
                      transition: 'opacity 5s ease'
                    }}
                  />
                </Box>
              </Paper>
            ) : (
              exportFormat.settings && exportFormat.settings.length > 0 && <SettingsTable />
            )}
          </Grid>
        </Grid>

        {exportformatSettings && Object.keys(exportformatSettings).length > 0 && (
          <ExportFormatBase
            settings={exportformatSettings}
            onSubmit={(values: any) => {
              return addColumn(values);
            }}
            onClose={() => {
              setExportFormatSettings({});
            }}
          />
        )}

        <Box textAlign={'center'} sx={{ mt: 2 }}>
          <Button component={Link} to={`/boi/extractor/export-formats/list`} variant="contained">
            Retour
          </Button>
        </Box>
      </Paper>
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending' && action !== null}
        onClose={() => {
          setRequestStatus(null);
          setAction(null);
        }}
        onSuccess={() => {
          setRequestStatus(null);
          toggleNeedToFetch(true);
          setExportFormatSettings({});
          closeDialog();
          setAction(null);
        }}
        action={`${action}`}
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </>
  );
}

import React from 'react';
import { BirdzTitle, InputField, SelectField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { ExportFormatSettingsMapping } from './ExportFormatSettingsMapping';

export const ExportFormatSettingsText = ({ settings }: any) => {
  return (
    <>
      <BirdzTitle>Transformation</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Prefixe
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="text_prefix" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Suffixe
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="text_suffix" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Casse
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <SelectField
            name="text_upper_case"
            options={
              settings
                ? [
                    { value: true, label: 'Majuscule' },
                    { value: false, label: 'Minuscule' },
                    { value: null, label: 'Aucune modification' }
                  ].map((value: any) => {
                    return {
                      value: value.value,
                      label: value.label
                    };
                  })
                : []
            }
          />
        </Grid>
      </Grid>

      <ExportFormatSettingsMapping />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import {
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';
import axios from 'axios';
import { Button, CircularProgress, Alert, IconButton, Grid, Box } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import FileDownload from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import Error from '@mui/icons-material/Error';
import './import.scss';
import DndOrImport from './DndOrImport';
import { useFetchContext } from '../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../ModalWithLoader/ModalWithLoader';
import { PiFileCsv } from 'react-icons/pi';
import { removeAccents } from '../../../utils';
import styled from 'styled-components';
import { downloadFile } from '../../../utils';

export type NewFile = { type: 'file'; content: File } | { type: 'string'; content: string } | null;

const ImportHandler = ({
  url,
  cb,
  needTemplate
}: {
  url: string;
  cb?: (value: object[]) => void;
  needTemplate?: boolean;
}) => {
  const StyledLink = styled.a`
    cursor: 'pointer'};
    text-decoration: underline;
    color: 'blue';
    pointer-events: 'auto'};
  `;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFile, setNewFile] = useState<NewFile>(null);
  const [formatError, setFormatError] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);

  const fetchContext = useFetchContext();

  const { notif, notifOptions } = useNotif();

  const title = 'Importer';

  const handleClose = () => {
    setIsModalOpen(false);
    setNewFile(null);
    setRequestStatus(null);
    setFormatError(false);
  };

  useEffect(() => {
    if (newFile?.type === 'file') {
      const extension = newFile?.content.name.split('.').pop();
      setFormatError(extension !== 'csv');
    } else setFormatError(false);
  }, [newFile]);

  const readBinaryFile = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadFile = () => {
    if (!newFile) return;
    else if (newFile.type === 'file') {
      const fileName = newFile?.content?.name
        ? removeAccents(newFile?.content?.name)
        : 'imported_file';
      setRequestStatus('pending');
      readBinaryFile(newFile.content).then((file) => {
        axios({
          method: 'POST',
          url,
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': `attachment; filename=${fileName}`
          },
          data: file
        })
          .then((res) => {
            handleClose();
            notif({
              content: 'Import des données réalisé avec succès',
              type: 'success'
            });
            cb && cb(res.data);
            fetchContext.toggleNeedToFetch(true);
            setRequestStatus('success');
          })
          .catch(() => {
            setRequestStatus('error');
            notif({
              content: "Une erreur est survenue lors de l'import des données",
              type: 'error'
            });
          });
      });
    }
  };

  const importTemplate = async () => {
    downloadFile(`/api/boi/extractor/data-mapping/import-template/`, 'data_mapping_template.csv');
  };

  return (
    <>
      <BirdzNotif options={notifOptions} />
      <Box sx={{ width: '100%' }}>
        <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={() => setIsModalOpen(true)}>
          <FileDownload sx={{ mr: 1 }} /> {title}
        </Button>
        <ModalWithLoader
          openModal={isModalOpen}
          onClose={() => {
            handleClose();
          }}
          action="Import"
          status={requestStatus}
          setStatus={setRequestStatus}
        >
          <>
            <BirdzModalTitle onClose={handleClose}>Ajouter un fichier</BirdzModalTitle>
            <BirdzModalContent>
              {needTemplate && (
                <Grid sx={{ mb: 2 }}>
                  <StyledLink href={'#'} onClick={importTemplate}>
                    Télécharger un template
                  </StyledLink>
                </Grid>
              )}
              <div className="content w-100 d-flex justify-content-center align-items-center">
                {!newFile ? (
                  <div className="d-flex flex-column align-items-center">
                    <DndOrImport
                      setNewFile={(file: File) => setNewFile({ type: 'file', content: file })}
                    />
                  </div>
                ) : newFile?.type === 'file' ? (
                  <Grid container spacing={2} className="d-flex align-items-center">
                    <Grid item xs={2}>
                      fichier choisi :
                    </Grid>
                    <Grid item xs={9} className="file-container p-50 mt-1">
                      <div className="file-wrapper d-flex align-items-center">
                        <div className="mr-1">
                          {formatError ? (
                            <Error sx={{ color: 'red', fontSize: '40px' }} />
                          ) : (
                            <PiFileCsv color="#0288d1" fontSize="1.5em" />
                          )}
                        </div>
                        <div className="d-flex">
                          <div className="file-name">{newFile?.content.name}</div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete-file"
                        color={'inherit'}
                        onClick={() => setNewFile(null)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={9} className="p-0">
                      {formatError && (
                        <Alert severity="error" sx={{ mt: 1 }}>
                          le format doit être de type .csv
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                ) : (
                  <textarea
                    name="copy/paste"
                    rows={7}
                    style={{ width: '500px', marginTop: '7px' }}
                    onChange={(e: any) => setNewFile({ type: 'string', content: e.target.value })}
                    placeholder="coller les données ici"
                  />
                )}
              </div>
            </BirdzModalContent>
            <BirdzModalActions>
              <Button id="cancelUpload" variant="outlined" onClick={handleClose}>
                Annuler
              </Button>
              <Button
                id="confirmUpload"
                variant="contained"
                onClick={uploadFile}
                disabled={
                  formatError || requestStatus === 'pending' || newFile === null || !newFile.content
                }
              >
                <div className="confirm-button-icon">
                  {requestStatus === 'pending' ? (
                    <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />
                  ) : (
                    <FileDownload sx={{ mr: 1 }} />
                  )}
                </div>
                {title}
              </Button>
            </BirdzModalActions>
          </>
        </ModalWithLoader>
      </Box>
    </>
  );
};

export default ImportHandler;

import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { FormikErrors } from 'formik';

const ClientNameField = ({
  setFieldValue,
  name
}: {
  name?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<any>>;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nameSearch, setNameSearch] = useState('');
  const [clientNames, setClientNames] = useState<{ name: string; bconnect_id: string }[]>([]);

  const { id } = useParams();

  useEffect(() => {
    if (nameSearch.length >= 1) {
      setIsLoading(true);
      axios
        .get(`/api/boi/extractor/bconnect-clients/?name=${nameSearch}`)
        .then((response: AxiosResponse) => {
          const clientNames = response.data;
          setClientNames(clientNames);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    } else setClientNames([]);
  }, [nameSearch]);

  return (
    <Autocomplete
      loading={isLoading}
      disabled={id ? true : false}
      options={clientNames.map((el) => el.name)}
      renderInput={(params: any) => (
        <TextField
          {...params}
          onChange={(e) => {
            setNameSearch(e.target.value);
          }}
        />
      )}
      onChange={(e: any) => {
        setFieldValue('name', e.target.innerHTML);
        setFieldValue(
          'bconnect_id',
          clientNames.find((clientName) => clientName.name === e.target.innerHTML)?.bconnect_id
        );
      }}
      value={id && name}
      size="small"
    />
  );
};

export default ClientNameField;

import React, { useState } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { CircularProgress, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from 'axios';

const RetryButton = ({ id, disabled }: { id: number; disabled: boolean }) => {
  const { notif, notifOptions } = useNotif();
  const [pending, setPending] = useState(false);
  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={() => {
          setPending(true);
          axios.post(`/api/bstock/sessions/${id}/retry`).then(
            () => {
              setPending(false);
              notif({
                type: 'success',
                content: 'session relancée'
              });
            },
            (error: any) => {
              setPending(false);
              notif({
                type: 'error',
                content: <>Une erreur est survenue : {error?.response?.data?.detail}</>
              });
            }
          );
        }}
      >
        {pending ? <CircularProgress size={20} /> : <RefreshIcon fontSize="small" />}
      </IconButton>
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default RetryButton;

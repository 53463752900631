import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import Select from 'react-select';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle,
  ListField,
  ListPage
} from '@applications-terrains/birdz-react-library';
import { TourDevice } from '../types';
import { useTourFormContext } from '../context/tourFormContext';

type FrameType = { label: string; id: number };

const SetFrameTypes = ({
  devices,
  listFields,
  setSelectedDevices
}: {
  devices: TourDevice[];
  listFields: ListField[];
  setSelectedDevices: (value: TourDevice[]) => void;
}) => {
  const [frameTypes, setFrameTypes] = useState<FrameType[]>([]);
  const [selectedFrameTypes, setSelectedFrameTypes] = useState<FrameType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const { tourData, setTourData } = useTourFormContext();

  useEffect(() => {
    if (frameTypes.length === 0) {
      axios.get('/api/boe/breader/frame-types/').then((res) => {
        setFrameTypes(res.data.results);
      });
    }
  }, [frameTypes]);

  const title = `Attribuer des types de relevé aux équipements selectionnés`;

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button disabled={devices.length === 0} onClick={() => setOpenModal(true)}>
        {title}
      </Button>
      <BirdzModal open={openModal} onClose={handleClose}>
        <BirdzModalTitle>
          <Typography>{title}</Typography>
        </BirdzModalTitle>
        <BirdzModalContent>
          <Box sx={{ minHeight: '300px' }}>
            <Select
              isMulti
              options={frameTypes.map((type) => ({ label: type.label, value: type.id }))}
              onChange={(frameTypes) =>
                setSelectedFrameTypes(
                  frameTypes.map((frameType) => ({ label: frameType.label, id: frameType.value }))
                )
              }
              placeholder="types de relevé"
            />
            <ListPage loadedData={devices} fields={listFields} displayResultsNumber={false} />
          </Box>
        </BirdzModalContent>
        <BirdzModalActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              const ids = devices.map((device) => device.MODULE);
              const devicesForPatrimonyStep: TourDevice[] = [];
              const devicesForDevicesStep: TourDevice[] = [];
              if (tourData.patrimonyStep) {
                tourData.patrimonyStep.map((device: TourDevice) => {
                  if (ids.includes(device.MODULE)) {
                    devicesForPatrimonyStep.push({
                      ...device,
                      frame_type: selectedFrameTypes.map((type) => type.id),
                      frame_type_string: selectedFrameTypes.map((type) => type.label)
                    });
                    devicesForDevicesStep.push({
                      ...device,
                      frame_type: selectedFrameTypes.map((type) => type.id)
                    });
                  } else devicesForPatrimonyStep.push(device);
                });

                setTourData({
                  ...tourData,
                  patrimonyStep: devicesForPatrimonyStep,
                  devicesStep: devicesForDevicesStep
                });
              }
              handleClose();
              setSelectedDevices([]);
            }}
          >
            Valider
          </Button>
        </BirdzModalActions>
      </BirdzModal>
    </>
  );
};

export default SetFrameTypes;

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import useReinitializeForm from '../../hooks/useReinitializeForm';

type ClearableSelectType<Option> = {
  options: Option[];
  onChange: (value: Option) => void;
  disabled?: boolean;
  defaultValue?: Option | null;
  isLoading?: boolean;
};

function ClearableSelect<Option>({
  options,
  onChange,
  disabled,
  defaultValue,
  isLoading
}: ClearableSelectType<Option>) {
  const [value, setValue] = useState<Option | null>(defaultValue || null);
  const reset = useReinitializeForm();

  useEffect(() => {
    if (reset) {
      setValue(null);
      onChange(null as Option);
    }
  }, [reset]);

  return (
    <Select
      id="select"
      options={options}
      onChange={(value) => {
        setValue(value);
        onChange(value as Option);
      }}
      value={value}
      isMulti={false}
      isLoading={isLoading || false}
      loadingMessage={() => 'Chargement en cours...'}
      noOptionsMessage={() => 'Aucune valeur à sélectionner'}
      placeholder=""
      isClearable
      isDisabled={disabled}
    />
  );
}

export default ClearableSelect;

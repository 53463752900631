import React, { useState } from 'react';
import { AppPaper, ListPage } from '@applications-terrains/birdz-react-library';
import Select from 'react-select';
import { Grid } from '@mui/material';
import SetFrameTypes from './SetFrameTypes';
import { TourDevice } from '../types';
import { useTourFormContext } from '../context/tourFormContext';

const TourListPage = () => {
  const { tourData, selectedDevices, setSelectedDevices, refreshListPage } = useTourFormContext();
  const devices = tourData.patrimonyStep;
  const [filterByType, setFilterByType] = useState<string | undefined>();

  const sortWithPriority = (items: TourDevice[]): TourDevice[] => {
    const priorityIds = new Set(selectedDevices.map((item) => item.ID || item.MODULE));

    return items.sort((a, b) => {
      const aInPriority = priorityIds.has(a.ID || a.MODULE);
      const bInPriority = priorityIds.has(b.ID || b.MODULE);

      if (aInPriority && !bInPriority) {
        return -1; // a vient avant b
      } else if (!aInPriority && bInPriority) {
        return 1; // b vient avant a
      } else {
        return 0; // l'ordre reste inchangé pour les éléments non prioritaires
      }
    });
  };

  const devicesToDisplay = devices
    ? filterByType
      ? devices.filter((device) => device.device_type === filterByType)
      : devices
    : [];

  const deviceTypes = [...new Set(tourData?.patrimonyStep?.map((device) => device.device_type))];

  const listFields = [
    { name: 'MODULE', label: 'Id' },
    {
      name: '',
      label: 'Adresse',
      transform: (_: any, device: TourDevice) => {
        return `${device.NUM_RUE_ABO} ${device.RUE_ABO} ${device.COMMUNE_ABO}` || '-';
      }
    },
    { name: 'device_type', label: 'Type', options: { width: '50px' } },
    {
      name: 'frame_type',
      label: 'Type de relevé',
      transform: (_: any, device: TourDevice) => {
        return device.frame_type_string || '-';
      }
    }
  ];

  return (
    <>
      <AppPaper sx={{ p: 2, mt: 2 }}>
        <Grid container alignItems={'center'} sx={{ my: 2, p: 0, gap: '1rem 0' }}>
          <Grid item xs={2}>
            {'Filtrer le tableau par'}
          </Grid>
          <Grid item xs={2}>
            <Select
              isClearable
              options={deviceTypes.map((type) => ({
                label: type,
                value: type
              }))}
              onChange={(type) => setFilterByType(type ? type.value : undefined)}
              placeholder="type"
            />
          </Grid>
          <Grid item xs={8} className="d-flex justify-content-end">
            <SetFrameTypes
              devices={selectedDevices}
              setSelectedDevices={setSelectedDevices}
              listFields={listFields.filter((field) => field.name !== 'frame_type')}
            />
          </Grid>
        </Grid>
        <div style={{ position: 'relative' }}>
          {devicesToDisplay.length > 0 && (
            <div
              style={{
                position: 'absolute',
                width: '50px',
                height: '30px',
                marginTop: '50px',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (
                  selectedDevices.length === 0 ||
                  (selectedDevices.length > 0 && selectedDevices.length < devicesToDisplay.length)
                ) {
                  setSelectedDevices(devicesToDisplay);
                } else setSelectedDevices([]);
              }}
            ></div> //override select All checkbox
          )}
          <ListPage
            loadedData={refreshListPage ? [] : sortWithPriority(devicesToDisplay)}
            fields={listFields}
            onSelectItems={(selectedDevices) => {
              setSelectedDevices(selectedDevices as TourDevice[]);
            }}
            selectedItems={selectedDevices}
          />
        </div>
      </AppPaper>
    </>
  );
};

export default TourListPage;

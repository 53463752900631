import React, { useEffect, useState } from 'react';
import { AppPaper, BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CrStatisticsPie from '../../../../CR/Statistics/CrStatisticsPie';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { PiFileCsv } from 'react-icons/pi';
import { saveAs } from 'file-saver';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { TourDetail, TourDevice } from './types';
import TourFormBOE from './TourFormBOE';
import { authService } from '../../../../..';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl
});

export default function TourDetails() {
  const { id } = useParams();
  const [tour, setTour] = useState<TourDetail>();
  const [tourMap, setTourMap] = useState<any>([]);
  const [pointsTour, setPointsTour] = useState<[number, number][]>([]);
  const [statistics, setStatistics] = useState<{ name: string; value: number }[]>([]);
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();
  const [patrimonyStep, setPatrimonyStep] = useState<TourDevice[]>([]);

  const endpoint = authService.canAccessBReaderBOE()
    ? `/api/boe/breader/tours/${id}`
    : authService.canAccessBOI()
      ? `/api/boi/breader/tours/${id}`
      : '';

  const [isBoe, setIsBoe] = useState<boolean>();

  //create a tour from theses devices
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchTourDetails = async () => {
    await axios.get(endpoint).then((response) => {
      const data = response.data;
      setTour(data);
      setTourMap(data.tour_devices.map((item: any) => item.data));
      setPointsTour(
        data.tour_devices.map((item: any) => {
          return [Number(item.data.COORD_Y), Number(item.data.COORD_X)];
        })
      );
    });
  };

  useEffect(() => {
    fetchTourDetails();
    authService.canAccessBReaderBOE() ? setIsBoe(true) : setIsBoe(false);
  }, []);

  useEffect(() => {
    const statsToTransform = tour ? tour.statistics : {};
    const statsToAdd = [];

    for (const property in statsToTransform) {
      let data;
      switch (property) {
        case 'total':
          data = { name: 'Total', value: statsToTransform[property] };
          break;
        case 'devices_ok':
          data = { name: 'Relevés', value: statsToTransform[property] };
          break;
        case 'devices_nok':
          data = { name: 'Non relevés', value: statsToTransform[property] };
          break;
        case 'devices_partially':
          data = { name: 'Relevés partiels', value: statsToTransform[property] };
          break;
        default:
          data = { name: property, value: statsToTransform[property] };
          break;
      }
      statsToAdd.push(data);
    }
    setStatistics(statsToAdd);
  }, [tour]);

  useEffect(() => {
    const arrayOfDevices: TourDevice[] = [];
    tour &&
      tour.tour_devices.map((item) => {
        arrayOfDevices.push({
          COORD_X: item.data.COORD_X ?? '',
          COORD_Y: item.data.COORD_Y ?? '',
          ID: item.data.ID ?? '',
          NUM_RUE_ABO: item.data.NUM_RUE_ABO ?? '',
          RUE_ABO: item.data.RUE_ABO ?? '',
          COMMUNE_ABO: item.data.COMMUNE_ABO ?? '',
          frame_type: item.frame_type,
          device_type: item.device_type,
          MODULE: item.data.MODULE ?? ''
        });
      });
    setPatrimonyStep(arrayOfDevices);
  }, [tour]);

  const exportTour = async () => {
    setExportInProgress(true);

    await axios
      .get(`/api/bo${isBoe ? 'e' : 'i'}/breader/tours/${id}/export/`, {
        responseType: 'blob',
        headers: {
          Accept: '*/*'
        }
      })
      .then((response) => {
        const [, filename] = response.headers['content-disposition'].split('filename=');
        notif({
          content: "L'export a été réalisé avec succès",
          type: 'success'
        });
        saveAs(response.data, filename);
      })
      .catch(() => {
        notif({
          content: "L'export a échoué",
          type: 'error'
        });
      })
      .finally(() => {
        setExportInProgress(false);
      });
  };

  return (
    <>
      <AppPaper>
        <Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 1,
              mt: 2
            }}
          >
            <Typography variant="overline">
              <strong>{tour && tour.name}</strong>
            </Typography>
          </Box>

          <Box sx={{ mt: 2, p: 2 }}>
            {tourMap && tourMap.length ? (
              <MapContainer center={pointsTour[0]} zoom={14} style={{ height: '550px' }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {pointsTour.map((position, idx) => (
                  <Marker key={`marker-${idx}`} position={position} />
                ))}
              </MapContainer>
            ) : (
              <CircularProgress />
            )}
          </Box>

          <Box sx={{ mt: 2 }}>
            <AppPaper>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2
                }}
              >
                <Typography variant="overline">Statistiques de la tournée</Typography>
              </Box>

              <Box mx="auto" sx={{ width: '50%' }}>
                <CrStatisticsPie
                  data={statistics}
                  displayMode="values"
                  verticalAlign="middle"
                  align="right"
                />
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {statistics.map((item: any) => {
                      return (
                        <TableRow key={item.name}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            {item.name}
                          </TableCell>
                          <TableCell align="center">{item.value}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </AppPaper>
          </Box>

          <Grid container alignItems="center" sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Box textAlign="left">
                <Button disabled={exportInProgress} variant="outlined" onClick={() => exportTour()}>
                  <PiFileCsv />
                  <Typography sx={{ ml: 1 }}>Exporter la tournée</Typography>
                </Button>
              </Box>
            </Grid>
            {isBoe && (
              <Grid item xs={6}>
                <Box textAlign="right">
                  <Button variant="contained" onClick={handleClickOpen}>
                    Créer une nouvelle tournée avec les compteurs filtrés
                  </Button>
                  <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {'Créer une nouvelle tournée avec les compteurs filtrés'}
                    </DialogTitle>
                    <DialogContent>
                      <TourFormBOE
                        tour={
                          tour && {
                            name: tour.name + ' copie',
                            patrimonyStep: patrimonyStep,
                            devicesStep: null,
                            readerStep: { label: tour.reader_name, value: tour.reader }
                          }
                        }
                      />
                    </DialogContent>
                  </Dialog>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </>
  );
}

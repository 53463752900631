import React from 'react';
import { Contract } from '../ContractsDropdown';
import OptionLabel from '../OptionLabel';

const getLabel = (contract: Contract) =>
  contract.client + ' / ' + contract.contract_ve + ' (' + contract.contract_management_code + ')';

export const getOption = (contract: Contract) => {
  const formattedContract = {
    ...contract,
    group: contract.client || contract.contract_client || '',
    client: contract.client || contract.contract_client || '',
    contract_management_code:
      'contract_management_code' in contract ? contract.contract_management_code : contract.contract
  };
  return {
    optionLabel: <OptionLabel contract={formattedContract} />,
    label: getLabel(formattedContract),
    group: formattedContract.group.trim(),
    value: formattedContract.contract_management_code
  };
};

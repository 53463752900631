import React, { useState } from 'react';
import { FormField, Item } from '@applications-terrains/birdz-react-library';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchContext } from '../../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';

const ReaderForm = ({ endpoint }: { endpoint: string }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setNotifObj, toggleNeedToFetch } = useFetchContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [openModal, setOpenModal] = useState(true);

  const handleClose = () => {
    setOpenModal(false);
    setTimeout(() => {
      navigate(-1);
    }, 500);
  };

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom / prénom',
      validation: Yup.string().required('Vous devez renseigner les nom et prénom')
    },
    {
      name: 'email',
      type: 'text',
      label: 'Email',
      validation: Yup.string().required('Vous devez renseigner un email')
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Téléphone',
      validation: Yup.string().required('Vous devez renseigner un numéro de téléphone')
    },
    {
      name: 'password',
      type: 'password',
      label: 'Mot de passe',
      validation: id ? Yup.string() : Yup.string().required('Vous devez renseigner un mot de passe')
    }
  ];

  const transformItemOnSave = (values: { password: string }) => {
    setRequestStatus('pending');
    const { password, ...rest } = values;
    return password ? values : rest;
  };

  return (
    <ModalWithLoader
      openModal={openModal}
      onClose={() => {
        handleClose();
      }}
      status={requestStatus}
    >
      <Item
        action={id ? 'edit' : 'add'}
        endpoint={endpoint}
        fields={formFields}
        name={`${id ? 'Modifier le' : 'Ajouter un'} releveur`}
        transformItemOnSave={transformItemOnSave}
        onSave={() => {
          handleClose();
          toggleNeedToFetch(true);
          setNotifObj({
            content: `Le releveur a été ${id ? 'modifié' : 'ajouté'} avec succès`,
            type: 'success'
          });
          setRequestStatus('success');
        }}
      />
    </ModalWithLoader>
  );
};

export default ReaderForm;

import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { useSaveJob } from './hooks/useSaveJob';
import { Job } from './JobForm';
import {
  BirdzNotif,
  useNotif,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';

const ToggleActiveOrInactive = ({
  job,
  setRefresh
}: {
  job: Job;
  setRefresh: (status: boolean) => void;
}) => {
  const { notif, notifOptions } = useNotif();
  const { saveJob, notification, submitInProgress } = useSaveJob();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  const [active, setActive] = useState(job.active);

  useEffect(() => {
    if (notification) {
      notif(notification);
      closeDialog();
      setTimeout(() => {
        setRefresh(true);
      }, 2000);
    }
  }, [notification]);

  const handleClick = () => {
    confirmDialog({
      title: `${active ? 'Désactiver' : 'Activer'} le job`,
      content: `Êtes-vous sûr de vouloir ${active ? 'désactiver' : 'activer'} ce job ?`,
      onValidate: () => {
        setActive(!active);
        saveJob({ ...job, active: !active });
      },
      onCancel: () => {
        closeDialog();
      }
    });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        {submitInProgress ? (
          <CircularProgress size={18} thickness={5} />
        ) : active ? (
          <ToggleOnIcon fontSize="small" sx={{ color: '#70b873' }} />
        ) : (
          <ToggleOffIcon fontSize="small" sx={{ color: 'red', opacity: 0.6 }} />
        )}
      </IconButton>
      <BirdzNotif options={notifOptions} />
      <BirdzDialog options={dialogOptions} />
    </>
  );
};

export default ToggleActiveOrInactive;

import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  SearchForm
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { useFetchContext } from '../../../contexts/fetchContext';
import Delete from '@mui/icons-material/Delete';
import ContractsDropdown from '../../Contracts/ContractsDropdown';
import useReinitializeForm from '../../../hooks/useReinitializeForm';
import Search from '@mui/icons-material/Search';
import { useGetAgentsString } from '../../../hooks/datarefs';
import RetryButton from './RetryButton';

type Session = {
  id: number;
  name: string;
  rif_update_status: null | 'OK' | 'NOK' | 'PARTIALLY_OK' | 'RIF_SERVER_DOWN';
};

const SessionsList = () => {
  const endpoint = '/api/bstock/sessions/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const fetchContext = useFetchContext();
  const reset = useReinitializeForm();
  const { data: agents } = useGetAgentsString();

  const [contractString, setContractString] = useState<string>('');
  const [filterString, setFilterString] = useState<string>('');

  useEffect(() => {
    reset && setContractString('');
  }, [reset]);

  const listFields = [
    { name: 'id', label: '#' },
    { name: 'contract_client', label: 'Client' },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (date: number) => {
        return (date && moment.unix(date).format('DD/MM/YYYY à HH:mm:ss')) || '-';
      }
    },
    { name: 'operation_type', label: 'Type' },
    { name: 'configuration_name', label: 'Configuration' },
    { name: 'devices_count', label: 'Nb de modules' },
    { name: 'agent', label: 'Agent' },
    {
      name: 'rif_update_status',
      label: 'Statut MAJ RIF',
      transform: (status: Session['rif_update_status']) => {
        return status || '-';
      }
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (session: Session) => {
        return (
          <IconButton component={Link} to={`/bstock/sessions/${session.id}`}>
            <Search fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-session',
      label: 'Supprimer la session',
      render: (model: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la session',
                content: 'Êtes-vous sûr de vouloir supprimer cette session ?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'La session a été supprimée avec succès',
                        type: 'success'
                      });
                      fetchContext.toggleNeedToFetch(true);
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'retry-session',
      label: 'Relancer la session',
      render: ({ rif_update_status, id }: Session) => {
        return <RetryButton id={id} disabled={rif_update_status !== 'RIF_SERVER_DOWN'} />;
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'date_after',
      label: 'Date session entre le ',
      type: 'datepicker'
    },
    {
      name: 'date_before',
      label: 'et le',
      type: 'datepicker'
    },
    {
      name: 'device_id',
      label: 'Identifiant module',
      type: 'text'
    },
    {
      name: 'counter',
      label: 'Identifiant compteur',
      type: 'text'
    },
    {
      name: 'operation_type',
      label: 'Type',
      type: 'select',
      options: {
        values: [
          { value: 'PAIRING_CONFIGURATION', label: 'Appairage et Configuration' },
          { value: 'RECONFIGURATION', label: 'Reconfiguration' }
        ]
      }
    },
    {
      name: 'agent',
      label: 'Agent',
      type: 'select',
      options: {
        values:
          agents?.map((agent: string) => ({
            label: agent,
            value: agent
          })) || []
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des sessions</BirdzTitle>
        </Grid>
      </Grid>
      <AppPaper>
        <Grid xs={12} container alignItems={'center'} sx={{ my: 2, p: 0 }}>
          <Grid item xs={1.49}>
            {'Client'}
          </Grid>
          <Grid item xs={10.51}>
            <ContractsDropdown
              isMulti={false}
              onChange={(contractNb) => setContractString('&contract=' + contractNb)}
              endpoint="/api/bstock/sessions/contracts/"
            />
          </Grid>
        </Grid>
        <SearchForm
          fields={searchFields}
          onSubmit={(filterString) => {
            setFilterString(contractString + filterString);
          }}
        />
      </AppPaper>
      <ListPage endpoint={endpoint} fields={listFields} filters={filterString} actions={actions} />

      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default SessionsList;

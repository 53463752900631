import React, { useEffect, useState } from 'react';
import { BirdzDialog, useDialog, ListPage } from '@applications-terrains/birdz-react-library';
import { Link, useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Select from 'react-select';
import { Client, ModuleFamilyType } from './ClientsForm';
import { Erp } from '../Erps/ErpForm';
import { formatModuleFamiliesForDB, getFormattedContacts } from './utils';
import { getERPNameAndVersion } from '../Erps/utils';

const AssociatedERPs = ({
  client,
  moduleFamilies,
  setRefresh,
  notif
}: {
  setRefresh: (value: boolean) => void;
  notif: (options: any) => void;
  client: Client;
  moduleFamilies: ModuleFamilyType[];
}) => {
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { id } = useParams();

  const [ERPs, setERPs] = useState<(Erp & { availableToAdd: boolean })[]>([]);
  const [erpToAdd, setErpToAdd] = useState<{ name: string; id: number } | null>(null);

  useEffect(() => {
    if (id) {
      axios.get(`/api/boi/extractor/erps`).then((response: AxiosResponse) => {
        const erps = response.data.results;
        setERPs(
          erps.map((erp: Erp) => ({
            ...erp,
            availableToAdd: !client.erp?.includes(erp.id)
          }))
        );
      });
    }
  }, [id]);

  return (
    <>
      <Grid justifyContent="space-between" container sx={{ mt: 6 }}>
        <Grid item>
          <h4>Liste des ERP associés</h4>
        </Grid>
        <Grid item sx={{ mt: 3 }}>
          <div style={{ display: 'flex' }}>
            <Select
              name="erps"
              options={ERPs.filter((erp) => erp.availableToAdd).map((erp) => ({
                label: getERPNameAndVersion(erp),
                value: erp.id
              }))}
              onChange={(selectedOption) => {
                setErpToAdd({
                  name: selectedOption?.label as string,
                  id: selectedOption?.value as number
                });
              }}
            />
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              disabled={!erpToAdd}
              onClick={() => {
                erpToAdd &&
                  confirmDialog({
                    title: 'Associer un ERP',
                    content: `Êtes-vous sûr de vouloir associer l'ERP ${erpToAdd.name} ?`,
                    onValidate: () => {
                      const payload = {
                        ...client,
                        ...getFormattedContacts(client),
                        erp: [...(client.erp as []), erpToAdd.id],
                        module_families:
                          client.module_families && moduleFamilies
                            ? formatModuleFamiliesForDB(client.module_families, moduleFamilies)
                            : []
                      };
                      axios.put(`/api/boi/extractor/clients/${id}`, payload).then(
                        () => {
                          notif({
                            content: `L'ERP ${erpToAdd.name} a été associé avec succès`,
                            type: 'success'
                          });
                          setRefresh(true);
                          setErpToAdd(null);
                        },
                        () => {
                          notif({
                            content: 'Une erreur est survenue lors de la mise à jour',
                            type: 'error'
                          });
                        }
                      );
                      closeDialog();
                    },
                    onCancel: () => {
                      closeDialog();
                    }
                  });
              }}
            >
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un ERP
            </Button>
          </div>
        </Grid>
      </Grid>
      <ListPage
        loadedData={client?.erp_objects || []}
        actions={[
          {
            name: 'voir',
            render: (erp: { name: string; description: string; id: number | string }) => {
              return (
                <IconButton component={Link} to={`/boi/extractor/erps/edit/${erp.id}`}>
                  <Visibility fontSize="small" />
                </IconButton>
              );
            }
          },
          {
            name: 'delete-erp',
            label: "Supprimer l'association avec l'ERP",
            render: (erp: Erp) => {
              const erpObj = ERPs.find((obj) => obj.id === erp.id);
              return (
                <IconButton
                  onClick={() => {
                    confirmDialog({
                      title: "Supprimer l'association avec un ERP",
                      content: `Êtes-vous sûr de vouloir supprimer l'association avec l'ERP ${erpObj ? getERPNameAndVersion(erpObj) : erp.name} ?`,
                      onValidate: () => {
                        const payload = {
                          ...client,
                          ...getFormattedContacts(client),
                          erp: client.erp?.filter((erpId) => erpId !== erp.id),
                          module_families:
                            client.module_families && moduleFamilies
                              ? formatModuleFamiliesForDB(client.module_families, moduleFamilies)
                              : []
                        };
                        axios.put(`/api/boi/extractor/clients/${id}`, payload).then(
                          () => {
                            notif({
                              content: `L'association avec l'ERP ${erpObj ? getERPNameAndVersion(erpObj) : erp.name} a été supprimée avec succès`,
                              type: 'success'
                            });
                            setRefresh(true);
                          },
                          () => {
                            notif({
                              content: 'Une erreur est survenue lors de la suppression',
                              type: 'error'
                            });
                          }
                        );
                        closeDialog();
                      },
                      onCancel: () => {
                        closeDialog();
                      }
                    });
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              );
            }
          }
        ]}
        fields={[
          {
            name: 'name',
            label: 'Nom',
            transform: (val, row) => {
              const erp = ERPs.find((erp) => erp.id === row.id);
              return erp ? getERPNameAndVersion(erp) : val || '-';
            }
          },
          {
            name: 'description',
            label: 'Description'
          }
        ]}
        displayResultsNumber={false}
        displayPaginationOptions={false}
      />
      <BirdzDialog options={dialogOptions} />
    </>
  );
};

export default AssociatedERPs;

import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import TourListPage from './TourListPage';
import TourListing from './TourListing';
import TourMapWrapper from './TourMapWrapper';

const DevicesStep = () => {
  const [tab, setTab] = useState<number>(0);
  const tabs = {
    0: 'Sélection via Carte',
    1: 'Listing'
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tab} onChange={(_, newValueTab: number) => setTab(newValueTab)}>
          {Object.entries(tabs).map(([key, label]) => (
            <Tab key={key} label={label} />
          ))}
        </Tabs>
      </Box>

      {tab === 0 && <TourMapWrapper />}
      {tab === 1 && <TourListing />}

      <TourListPage />
    </>
  );
};

export default DevicesStep;

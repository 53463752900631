import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Delete from '@mui/icons-material/Delete';
import { useFetchContext } from '../../../../contexts/fetchContext';
import ReaderForm from './ReaderForm';
import { useLocation, useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';

type Reader = {
  id: number;
  name: string;
};

const ReadersList = () => {
  const endpoint = '/api/boe/breader/readers/';
  const location = useLocation();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const { needToFetch, toggleNeedToFetch, notifObj, setNotifObj } = useFetchContext();
  const navigate = useNavigate();

  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    setOpenForm(location.pathname.includes('add') || location.pathname.includes('edit'));
  }, [location]);

  useEffect(() => {
    if (notifObj) {
      notif(notifObj);
      setNotifObj(undefined);
    }
  }, [location]);

  const listFields = [
    {
      name: 'name',
      label: 'Nom prénom'
    },
    {
      name: 'email',
      label: 'Email'
    },
    {
      name: 'phone',
      label: 'Téléphone'
    }
  ];

  const actions = [
    {
      name: 'modifier',
      label: 'Modifier le releveur',
      render: (reader: Reader) => {
        return (
          <IconButton onClick={() => navigate('/breader/readers/edit/' + reader.id)}>
            <Edit fontSize="small" color="action" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-reader',
      label: 'Supprimer le releveur',
      render: (reader: Reader) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le releveur',
                content: 'Êtes-vous sûr de vouloir supprimer ce releveur ?',
                onValidate: () => {
                  axios
                    .delete(`${endpoint}/${reader.id}`)
                    .then(() => {
                      notif({
                        content: 'Le releveur a été supprimé avec succès',
                        type: 'success'
                      });
                      toggleNeedToFetch(true);
                      closeDialog();
                    })
                    .catch(() => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                      closeDialog();
                    });
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Releveurs</BirdzTitle>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => navigate('/breader/readers/add')}
          >
            <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un releveur
          </Button>
        </Grid>
      </Grid>
      {!needToFetch && <ListPage endpoint={endpoint} fields={listFields} actions={actions} />}
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
      {openForm && <ReaderForm endpoint={endpoint} />}
    </AppPaper>
  );
};

export default ReadersList;

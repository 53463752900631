import React, { useEffect, useState } from 'react';
import { useTourFormContext } from '../context/tourFormContext';
import { Button, Grid } from '@mui/material';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import axios, { AxiosResponse } from 'axios';
import ContractsDropdown from '../../../../../Contracts/ContractsDropdown';
import ModalWithLoader, {
  RequestStatus
} from '../../../../../Tools/ModalWithLoader/ModalWithLoader';
import ClearableSelect from '../../../../../Tools/ClearableSelect';

type City = {
  label: string;
  zip_code: string;
  insee: string;
};

const SiBirdzImport = () => {
  const [selectedContract, setSelectedContract] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [cities, setCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const { tourData, setTourData, setRefreshDevicesStep, setCurrentStep } = useTourFormContext();

  useEffect(() => {
    requestStatus === 'success' &&
      setTimeout(() => {
        setRequestStatus(null);
      }, 2000);
  }, [requestStatus]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('/api/boe/breader/contracts-cities/')
      .then((response: AxiosResponse) => {
        const cities = response.data;
        setCities(cities);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [axios]);

  const handleClose = () => {
    setRequestStatus(null);
  };
  return (
    <AppPaper sx={{ pt: 2 }}>
      <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          CONTRAT
        </Grid>
        <Grid item xs={10}>
          <ContractsDropdown
            isMulti={false}
            onChange={(contractNb) => setSelectedContract(contractNb as string)}
            endpoint={`/api/boe/breader/contracts/`}
            searchKey="contract_ve"
          />
        </Grid>
      </Grid>
      <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          COMMUNE
        </Grid>
        <Grid item xs={10}>
          <ClearableSelect
            isLoading={isLoading}
            options={cities.map((city) => ({
              label: city.label + ' ' + city.zip_code,
              value: city.insee
            }))}
            onChange={(city) => {
              setSelectedCity(city.value);
            }}
            disabled={!isLoading && cities.length === 0}
          />
        </Grid>
      </Grid>
      <Grid container className="d-flex" justifyContent={'end'} sx={{ gap: 1 }}>
        <Button
          variant="contained"
          // disabled={!selectedCity || !selectedContract}
          size="small"
          onClick={() => {
            setRequestStatus('pending');
            axios
              .get(
                `/api/boe/breader/data-from-patrimony/?contract_code=${selectedContract || ''}&insee_code=${selectedCity || ''}`
              )
              .then((response: any) => {
                setRefreshDevicesStep(true);
                if (response.data.length > 0) {
                  setCurrentStep('devicesStep');
                  setTourData({
                    ...tourData,
                    patrimonyStep: response.data,
                    devicesStep: null
                  });
                  setRequestStatus('success');
                } else {
                  setRequestStatus('successWithNoResults');
                  setTourData({
                    ...tourData,
                    patrimonyStep: null,
                    devicesStep: null
                  });
                }
              })
              .catch(() => setRequestStatus('error'));
          }}
        >
          Rechercher
        </Button>
      </Grid>
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          handleClose();
        }}
        action="Import des devices"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
};

export default SiBirdzImport;

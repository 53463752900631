import React from 'react';
import { SwitchField } from '@applications-terrains/birdz-react-library';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const CustomSwitchField = ({ name, label }: { name: string; label: string }) => {
  const switchTheme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            // Controls default (unchecked) color for the thumb
            color: '#ccc'
          },
          colorPrimary: {
            '&.Mui-checked': {
              // Controls checked color for the thumb
              color: '#81c784'
            }
          },
          track: {
            // Controls default (unchecked) color for the track
            opacity: 0.5,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            '.Mui-checked.Mui-checked + &': {
              // Controls checked color for the track
              opacity: 0.7,
              backgroundColor: '#81c784'
            }
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={switchTheme}>
      <SwitchField name={name} label={label} />
    </ThemeProvider>
  );
};

export default CustomSwitchField;

import React from 'react';
import { useTourFormContext } from '../context/tourFormContext';
import TourMap from './TourMap';

const TourMapWrapper = () => {
  const { tourData, setSelectedDevices, setRefreshListPage, refreshDevicesStep } =
    useTourFormContext();
  const devices = tourData.patrimonyStep;

  return (
    <>
      {devices && !refreshDevicesStep && (
        <TourMap
          devices={devices}
          onSelected={(value) => {
            setSelectedDevices(value);
            setRefreshListPage(true);
          }}
        />
      )}
    </>
  );
};

export default TourMapWrapper;

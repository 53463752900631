import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Button } from '@mui/material';
import { AppPaper, FormField, Item } from '@applications-terrains/birdz-react-library';
import ContractsDropdown from '../../Contracts/ContractsDropdown';
import SupportsList from './Supports/SupportsList';
import useContractsData from '../../Contracts/hooks/useContractsData';

const BReaderClientForm = ({ action }: any) => {
  const { id } = useParams();
  const endpoint = `/api/boi/breader/clients/`;
  const navigate = useNavigate();
  const { contractsData } = useContractsData(endpoint);

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom',
      validation: Yup.string().required('Vous devez renseigner le nom')
    },
    {
      name: 'contracts',
      type: 'custom',
      label: 'Contrats / clients',
      render: (value: string[], onChange: any) => {
        return (
          <ContractsDropdown
            required={true}
            isMulti={true}
            value={value}
            onChange={(contractNbs) => {
              onChange(contractNbs);
            }}
            contractsData={contractsData || []}
            searchKey="contract_ve"
          />
        );
      }
    },
    {
      name: 'nb_max_users',
      label: "Nb d'utilisateurs max",
      type: 'number',
      validation: Yup.number().min(0, "Le nombre d'utilisateurs maximum doit être supérieur à 0")
    },
    {
      name: 'is_active',
      type: 'radio',
      label: 'Etat',
      options: {
        values: [
          { value: true, label: 'Actif' },
          { value: false, label: 'Inactif' }
        ]
      },
      defaultValue: true
    }
  ];

  return (
    <AppPaper>
      {((id && contractsData && contractsData.length > 0) || !id) && (
        <Item
          action={action}
          endpoint={endpoint}
          fields={formFields}
          name={`${action === 'add' ? 'Ajouter un ' : 'Détails '} client B-Reader`}
        />
      )}
      {action === 'edit' && (
        <>
          <SupportsList />

          <Box sx={{ textAlign: 'center' }}>
            <Button variant="contained" sx={{ m: 2 }} onClick={() => navigate(-1)}>
              Retour à la liste
            </Button>
          </Box>
        </>
      )}
    </AppPaper>
  );
};
export default BReaderClientForm;

import React, { useEffect, useState } from 'react';
import {
  ListPage,
  AppPaper,
  BirdzTitle,
  useNotif,
  BirdzNotif,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Paper, TextField } from '@mui/material';
import { SearchField } from '@applications-terrains/birdz-react-library';
import { Correspondance, Translation } from '../types';
import _ from 'lodash';
import Select from 'react-select';
import useErpOptions from '../../Erps/useErpOptions';
import axios from 'axios';
import Delete from '@mui/icons-material/Delete';
import EditTranslation from './EditTranslation';

const CorrespondanceDetails = () => {
  const { id } = useParams();

  const target = 'translations.results';

  const [correspondanceDetails, setCorrespondanceDetails] = useState<Correspondance>();
  const ErpOptions = useErpOptions();
  const { notif, notifOptions } = useNotif();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loading && setLoading(false);
  }, [loading]);

  const listFields = [
    { name: 'code', label: 'Code Birdz', orderable: true },
    { name: 'translation', label: 'Traduction ERP', orderable: true }
  ];

  const getFilters = () => {
    type Filter = { label: string; value: string };
    const codeValues: Filter[] = [];
    const translationValues: Filter[] = [];
    correspondanceDetails &&
      _.get(correspondanceDetails, target).map((el) => {
        codeValues.push({ label: el.code, value: el.code });
        translationValues.push({ label: el.translation, value: el.translation });
      });
    return {
      code: codeValues,
      translation: translationValues
    };
  };

  const handleErp = (event: any) => {
    const values: any = {
      ...correspondanceDetails,
      erp: event.value,
      erp_name: event.label
    };
    setCorrespondanceDetails(values);
  };

  const handleName = (event: any) => {
    const values: any = {
      ...correspondanceDetails,
      name: event.target.value
    };
    setCorrespondanceDetails(values);
  };

  const submit = async () => {
    await axios
      .put(`/api/boi/extractor/data-mapping/${correspondanceDetails?.id}/`, {
        id: correspondanceDetails?.id,
        name: correspondanceDetails?.name,
        erp: correspondanceDetails?.erp
      })
      .then(() => {
        notif({
          type: 'success',
          content: 'La liste de correspondance a bien été enregistrée'
        });
      })
      .catch(() => {
        notif({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement de la liste de correspondance"
        });
      });
  };

  const searchFields: SearchField[] = [
    {
      name: 'code',
      label: 'Nom',
      type: 'select',
      options: {
        values: getFilters().code
      }
    },
    {
      name: 'translation',
      label: 'Traduction',
      type: 'select',
      options: {
        values: getFilters().translation
      }
    }
  ];

  const actions = [
    {
      name: 'edit',
      label: 'Modifier la traduction',
      render: (translation: Translation) => {
        return (
          <>
            <EditTranslation translation={translation} setRefresh={setLoading} />
          </>
        );
      }
    },
    {
      name: 'delete-translation',
      label: 'Supprimer la traduction',
      render: (translation: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer la traduction',
                content: 'Êtes-vous sûr de vouloir supprimer cette traduction ?',
                onValidate: () => {
                  axios
                    .delete(`/api/boi/extractor/data-mapping-translation/${translation.id}/`)
                    .then(
                      () => {
                        notif({
                          content: 'La traduction a été supprimée avec succès',
                          type: 'success'
                        });
                      },
                      (error) => {
                        notif({
                          content: `${error.response.data.error}`,
                          type: 'error'
                        });
                      }
                    );
                  closeDialog();
                  navigate(0);
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}>
            <Delete fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Consultation d'une liste de correspondances</BirdzTitle>
        </Grid>
      </Grid>
      <Paper variant="outlined" square sx={{ p: 2, mb: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={1}>
            <Box>ERP</Box>
          </Grid>
          <Grid item xs={5}>
            <Select
              id="erp"
              value={ErpOptions.filter((item: any) => {
                return item.value === correspondanceDetails?.erp
                  ? { label: item.label, value: item.value }
                  : null;
              })}
              onChange={handleErp}
              options={ErpOptions}
            />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={1}>
            <Box>Nom</Box>
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              variant="outlined"
              value={correspondanceDetails?.name}
              onChange={handleName}
            />
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}>
            <Button
              variant="outlined"
              onClick={() => {
                confirmDialog({
                  title: 'Annuler les saisies',
                  content: 'Souhaitez-vous annuler vos saisies ?',
                  onValidate: () => {
                    closeDialog();
                    navigate(0);
                  },
                  onCancel: () => {
                    closeDialog();
                  },
                  okButtonLabel: 'Oui',
                  cancelButtonLabel: 'Non'
                });
              }}>
              Annuler
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={() => submit()}>
              Enregistrer
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {!loading && (
        <ListPage
          fields={listFields}
          searchFields={searchFields}
          actions={actions}
          endpoint={`/api/boi/extractor/data-mapping/${id}`}
          resultsField={target}
          displayPaginationOptions={true}
          onLoadItems={(_, data) => {
            setCorrespondanceDetails(data);
          }}
        />
      )}
      <BirdzNotif options={notifOptions} />
      <BirdzDialog options={dialogOptions} />
    </AppPaper>
  );
};

export default CorrespondanceDetails;

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { StepType, Tour, TourDevice } from '../types';

type TourFormContextType = {
  tourData: Tour;
  setTourData: (value: Tour) => void;
  refreshListPage: boolean;
  setRefreshListPage: (value: boolean) => void;
  refreshDevicesStep: boolean;
  setRefreshDevicesStep: (value: boolean) => void;
  selectedDevices: TourDevice[];
  setSelectedDevices: (value: TourDevice[]) => void;
  currentStep: StepType;
  setCurrentStep: (step: StepType) => void;
};

const TourFormContext = createContext<TourFormContextType | undefined>(undefined);

export const useTourFormContext = () => {
  const context = useContext(TourFormContext);

  if (context === undefined) {
    throw new Error('useTourFormContext must be used with a TourFormContext');
  }

  return context;
};

export const TourFormContextProvider = ({ children }: { children: ReactNode }) => {
  const [tourData, setTourData] = useState<Tour>({
    name: '',
    patrimonyStep: null,
    devicesStep: null,
    readerStep: null
  });
  const [selectedDevices, setSelectedDevices] = useState<TourDevice[]>([]);
  const [refreshListPage, setRefreshListPage] = useState(false);
  const [refreshDevicesStep, setRefreshDevicesStep] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepType>('name');

  useEffect(() => {
    refreshListPage && setRefreshListPage(false);
  }, [refreshListPage]);

  useEffect(() => {
    refreshDevicesStep && setRefreshDevicesStep(false);
  }, [refreshDevicesStep]);

  const value = {
    tourData,
    setTourData,
    selectedDevices,
    setSelectedDevices,
    refreshListPage,
    setRefreshListPage,
    refreshDevicesStep,
    setRefreshDevicesStep,
    currentStep,
    setCurrentStep
  };

  return <TourFormContext.Provider value={value}>{children}</TourFormContext.Provider>;
};

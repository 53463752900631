import React from 'react';
import { BirdzTitle, InputField, SelectField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { ExportFormatSettingsMapping } from './ExportFormatSettingsMapping';

export const ExportFormatSettingsNumber = () => {
  const separator = [
    { value: '', label: '' },
    { value: '.', label: 'Point (.)' },
    { value: ',', label: 'Virgule (,)' }
  ];
  return (
    <>
      <BirdzTitle>Format</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Séparateur décimal
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <SelectField name="number_decimal_separator" options={separator} fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Séparateur millier
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="number_thousand_separator" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Nombre décimales
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="number_digit_number_after_comma" fullWidth size="small" type="number" />
        </Grid>
      </Grid>

      <ExportFormatSettingsMapping />
    </>
  );
};

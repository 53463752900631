import React, { useState } from 'react';
import { Translation } from '../types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import axios from 'axios';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import Edit from '@mui/icons-material/Edit';

const EditTranslation = ({
  translation,
  setRefresh
}: {
  translation: Translation;
  setRefresh: (loading: boolean) => void;
}) => {
  const { notif, notifOptions } = useNotif();
  const [payload, setPayload] = useState<Translation>(translation);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submit = async () => {
    await axios
      .put(`/api/boi/extractor/data-mapping-translation/${translation?.id}/`, payload)
      .then(() => {
        handleClose();
        notif({
          type: 'success',
          content: 'La traduction a bien été enregistrée'
        });
        setTimeout(() => {
          setRefresh(true);
        }, 2000);
      })
      .catch(() => {
        notif({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement de la traduction"
        });
      });
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Edit fontSize="small" />
      </IconButton>
      <Dialog open={open} aria-labelledby="dialog-title" aria-describedby="dialog-description">
        <DialogTitle id="dialog-title">{'Modifier la traduction'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Box>Code Birdz</Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                defaultValue={translation?.code}
                onChange={(event) =>
                  setPayload({
                    ...payload,
                    code: event.target.value
                  })
                }
              />
            </Grid>

            <Grid item xs={6}>
              <Box>Traduction ERP</Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                defaultValue={translation?.translation}
                onChange={(event) =>
                  setPayload({
                    ...payload,
                    translation: event.target.value
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={() => submit()} autoFocus>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>

      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default EditTranslation;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Contract } from '../ContractsDropdown';
import axios from 'axios';

const useContractsData = (endpoint: string) => {
  const { id } = useParams();
  const [contractsData, setContractsData] = useState<Contract[] | null>(null);
  const [allSelected, setAllSelected] = useState<boolean | null>(null);

  useEffect(() => {
    if (id) {
      axios.get(endpoint + id).then((response) => {
        setContractsData(response.data.contracts_data);
        setAllSelected(response.data.select_all_contracts);
      });
    }
  }, [endpoint, id]);

  return { contractsData, allSelected };
};

export default useContractsData;

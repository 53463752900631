import React, { ReactNode, createContext, useContext, useState } from 'react';

type ExportListContextType = {
  contractWidths: number[];
  clientWidths: number[];
  addWidth: (target: 'client' | 'contract', value: number) => void;
  clearWidths: () => void;
};

const ExportListContext = createContext<ExportListContextType | undefined>(undefined);

export const useExportListContext = () => {
  const context = useContext(ExportListContext);

  if (context === undefined) {
    throw new Error('useExportListContext must be used with an ExportListContext');
  }

  return context;
};

export const ExportListContextProvider = ({ children }: { children: ReactNode }) => {
  const [clientWidths, setClientWidths] = useState<ExportListContextType['clientWidths']>([]);
  const [contractWidths, setContractWidths] = useState<ExportListContextType['contractWidths']>([]);

  const addWidth = (target: 'client' | 'contract', width: number) => {
    const widths = {
      client: {
        state: clientWidths,
        method: setClientWidths
      },
      contract: {
        state: contractWidths,
        method: setContractWidths
      }
    };
    !widths[target].state.includes(width) && widths[target].method((widths) => [...widths, width]);
  };

  const clearWidths = () => {
    setClientWidths([]);
    setContractWidths([]);
  };

  const value = {
    clientWidths,
    contractWidths,
    addWidth,
    clearWidths
  };

  return <ExportListContext.Provider value={value}>{children}</ExportListContext.Provider>;
};

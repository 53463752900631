import React, { useEffect, useState } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

const DataMappingHandler = ({ data_mapping }: { data_mapping: number | null }) => {
  const { notif, notifOptions } = useNotif();

  const [dataMapping, setDataMapping] = useState<{ name: string } | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (data_mapping && dataMapping === null) {
      setIsFetching(true);
      axios
        .get(`/api/boi/extractor/data-mapping/${data_mapping}/`)
        .then((response) => {
          setDataMapping(response.data);
          setIsFetching(false);
        })
        .catch(() => {
          () => {
            notif({
              content: 'Une erreur est survenue lors de la récupération des correspondances',
              type: 'error'
            });
          };
          setIsFetching(false);
        });
    }
  }, [data_mapping, dataMapping]);

  return (
    <>
      {isFetching ? <CircularProgress size={15} /> : dataMapping?.name || '-'}
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default DataMappingHandler;

import React, { ReactNode, useEffect, useState } from 'react';
import { AppPaper, BirdzTitle, ListPage } from '@applications-terrains/birdz-react-library';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import FileUploadIcon from '@mui/icons-material/FileUpload';

type Pairing = {
  counter: string;
  created_at: number;
  device_id: string;
  id: number;
  index: string;
  updated_at: number;
  rif_is_updated: boolean | null;
};

type Session = {
  agent: string;
  configuration: number;
  configuration_name: string;
  contract: string;
  contract_client: string;
  contract_ve: string;
  created_at: number;
  updated_at: number;
  devices_count: number;
  id: number;
  operation_type: 'RECONFIGURATION' | 'PAIRING_CONFIGURATION';
  pairings: Pairing[];
};

const SessionDetail = () => {
  const { id } = useParams();
  const { notif, notifOptions } = useNotif();
  const [session, setSession] = useState<Session>();

  const endpoint = `/api/bstock/sessions/${id}`;

  const listFields = [
    { name: 'device_id', label: 'Modules' },
    { name: 'water_meter', label: 'Compteur' },
    { name: 'index', label: 'Index' },
    {
      name: 'rif_is_updated',
      label: 'Commentaire MAJ SIF',
      transform: (_: any, { rif_is_updated }: Pairing) => {
        let status = '-';
        switch (rif_is_updated) {
          case true:
            status = 'Mis à jour';
            break;
          case false:
            status = 'Pas à jour';
            break;
          default:
            break;
        }

        return status;
      }
    }
  ];

  useEffect(() => {
    id && axios.get(endpoint).then((res) => setSession(res.data));
  }, [id, endpoint]);

  const Line = ({ label, value }: { label: string; value: ReactNode }) => (
    <Box className="d-flex" sx={{ my: 1 }}>
      <Box sx={{ width: '200px' }}>
        <strong style={{ color: '#637192' }}>{label} :</strong>
      </Box>
      <Box>{value}</Box>
    </Box>
  );

  const fields = [
    {
      label: 'Client',
      value:
        session?.contract_ve + ' / ' + session?.contract_client + ' (' + session?.contract + ')'
    },
    {
      label: 'Type',
      value: session?.operation_type
    },
    {
      label: 'Date',
      value: session && moment.unix(session?.created_at).format('DD/MM/YYYY à HH:mm:ss')
    },
    {
      label: 'Agent',
      value: session?.agent
    },
    {
      label: 'Profil configuration',
      value: session?.configuration_name
    },
    {
      label: 'Nombre de modules',
      value: session?.devices_count
    }
  ];

  //tmp
  useEffect(() => {
    setTimeout(() => {
      const waterp = document.getElementById('export_button_Export WaterP') as HTMLButtonElement;
      if (waterp) {
        waterp.className = waterp.className + ' Mui-disabled';
        waterp.disabled = true;
      }
    }, 300);
  }, []);

  return (
    <AppPaper>
      <BirdzTitle>Détail de la session</BirdzTitle>
      <AppPaper>
        <Box sx={{ mt: 3 }}>
          {fields.map((field) => (
            <Line label={field.label} value={field.value} key={field.label + field.value} />
          ))}
        </Box>
        <Box sx={{ mt: 3 }}>
          <ListPage
            loadedData={session?.pairings}
            fields={listFields}
            exportButtons={[
              {
                name: 'Export PREPAR',
                fileName: 'b_stock_prepar_export_' + id,
                type: 'csv',
                icon: <FileUploadIcon sx={{ mr: 0.5 }} />,
                requestEndpoint: `/api/bstock/sessions/${id}/export/`,
                keepQueryParams: false,
                onSuccess: () => {
                  notif({
                    type: 'success',
                    content: 'Export réussi'
                  });
                }
              },
              {
                name: 'Export WaterP',
                fileName: 'b_stock_waterp_export_' + id,
                type: 'csv',
                icon: <FileUploadIcon sx={{ mr: 0.5 }} />,
                // requestEndpoint: `/api/bstock/sessions/${id}/export/`,
                keepQueryParams: false,
                onSuccess: () => {
                  notif({
                    type: 'success',
                    content: 'Export réussi'
                  });
                }
              }
            ]}
          />
        </Box>
      </AppPaper>
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default SessionDetail;

import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import ImportHandler from '../../../../../Tools/import/ImportHandler';
import { useTourFormContext } from '../context/tourFormContext';
import { TourDevice } from '../types';
import SiBirdzImport from './SiBirdzImport';

const PatrimonyStep = () => {
  const { tourData, setTourData, setRefreshDevicesStep, setCurrentStep } = useTourFormContext();

  const [tab, setTab] = useState<number>(0);
  const tabs = {
    0: 'Via CSV',
    1: 'Via SI Birdz'
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tab} onChange={(_, newValueTab: number) => setTab(newValueTab)}>
          {Object.entries(tabs).map(([key, label]) => (
            <Tab key={key} label={label} />
          ))}
        </Tabs>
      </Box>

      {tab === 0 && (
        <ImportHandler
          url={`/api/boe/breader/import-tours-file/`}
          cb={(patrimonyData: object[]) => {
            setRefreshDevicesStep(true);
            setTourData({
              ...tourData,
              patrimonyStep: patrimonyData as TourDevice[],
              devicesStep: null
            });
            setCurrentStep('devicesStep');
          }}
        />
      )}
      {tab === 1 && <SiBirdzImport />}
    </>
  );
};

export default PatrimonyStep;

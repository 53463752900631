import { useEffect, useRef, useState } from 'react';

const useFocusWithin = () => {
  const ref = useRef<HTMLElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleFocus = () => {
      if (ref.current && ref.current.contains(document.activeElement)) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    };

    window.addEventListener('focus', handleFocus, true);
    window.addEventListener('blur', handleFocus, true);

    return () => {
      window.removeEventListener('focus', handleFocus, true);
      window.removeEventListener('blur', handleFocus, true);
    };
  }, []);

  return { ref, isFocused };
};

export default useFocusWithin;

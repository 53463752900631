import React, { useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { useFetchContext } from '../../../contexts/fetchContext';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import moment from 'moment';
import ModalWithLoader, { RequestStatus } from '../../Tools/ModalWithLoader/ModalWithLoader';

const BReaderClientsList = () => {
  const endpoint = '/api/boi/breader/clients/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const { needToFetch, toggleNeedToFetch } = useFetchContext();

  const listFields = [
    {
      name: 'name',
      label: 'Nom'
    },
    {
      name: 'created_at',
      label: 'Date de création',
      transform: (value: any) => {
        return value ? moment.unix(value).format('DD/MM/YYYY à HH:mm:ss') : '-';
      }
    }
  ];

  const actions = [
    {
      name: 'details',
      label: 'Voir les détails',
      render: (client: { id: number }) => {
        return (
          <IconButton component={Link} to={`/boi/administration/breader/clients/${client.id}`}>
            <Edit fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-client',
      label: 'Supprimer le client',
      render: (client: { id: number }) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le client',
                content: 'Êtes-vous sûr de vouloir supprimer ce client ?',
                onValidate: () => {
                  setRequestStatus('pending');
                  axios
                    .delete(`${endpoint}${client.id}`)
                    .then(() => {
                      setRequestStatus('success');
                    })
                    .catch(() => {
                      setRequestStatus('error');
                    });
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <Delete fontSize="small" color="action" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'reader_name',
      label: 'Nom du releveur',
      options: {
        identifier: 'name',
        source: '/api/boi/breader/tours/readers',
        label: 'name',
        searchIsMulti: false
      }
    }
  ];

  const handleCloseModalWithLoader = () => {
    closeDialog();
    setRequestStatus(null);
    toggleNeedToFetch(true);
  };

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Clients B-Reader</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/administration/breader/clients/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un client
            </Button>
          </Link>
        </Grid>
      </Grid>
      {!needToFetch && (
        <ListPage
          endpoint={endpoint}
          fields={listFields}
          actions={actions}
          searchFields={searchFields}
        />
      )}
      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => handleCloseModalWithLoader()}
        onSuccess={() => handleCloseModalWithLoader()}
        action="Suppression du client"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
};

export default BReaderClientsList;

import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import ModalWithLoader, { RequestStatus } from '../../Tools/ModalWithLoader/ModalWithLoader';
import axios from 'axios';
import { saveAs } from 'file-saver';

const PreviewJobHandler = ({ jobId }: { jobId: number }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <>
      <IconButton
        onClick={() => {
          setRequestStatus('pending');
          axios
            .get(`/api/boi/extractor/jobs/${jobId}/export-sample/`, { responseType: 'blob' })
            .then((response) => {
              const contentDisposition = response.headers['content-disposition'] || '';
              const headerFilename = contentDisposition.split('filename=')[1]; // Safely split
              const fileName = headerFilename ? headerFilename.replace(/"/g, '') : 'sample';
              saveAs(new Blob([response.data]), fileName);
              setRequestStatus('success');
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                const reader = new FileReader();
                reader.onload = function () {
                  const result = reader.result;
                  if (typeof result === 'string') {
                    const errorMessage = JSON.parse(result).error;
                    const formattedMessage =
                      errorMessage === 'there are no valid interventions to export'
                        ? "L'export est impossible car il n'y a pas de nouvelle intervention valide"
                        : `Une erreur est survenue : ${errorMessage}`;
                    setErrorMessage(formattedMessage);
                  }
                };
                reader.readAsText(error.response.data);
              } else {
                setErrorMessage(
                  `${error.message === 'Request failed with status code 400' ? 'La requête à échoué (erreur 400)' : 'Une erreur est survenue : ' + error.message}`
                );
              }
              setRequestStatus('error');
            });
        }}
      >
        <PreviewIcon fontSize="small" />
      </IconButton>
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        timeout={requestStatus === 'error' ? 3000 : 1500}
        onClose={() => {
          setRequestStatus(null);
        }}
        onSuccess={() => {
          setRequestStatus(null);
        }}
        action={`Génération de la prévisualisation du job`}
        status={requestStatus}
        setStatus={setRequestStatus}
        customErrorMessage={errorMessage}
      />
    </>
  );
};

export default PreviewJobHandler;

import { useEffect, useState } from 'react';
import { Contract } from '../ContractsDropdown';
import { SelectOptionType } from '../../Tools/Selects/types';
import { getOption } from './const';

const useGetContractsOptions = (contracts: Contract[]) => {
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  useEffect(() => {
    const options = contracts
      ?.map((contract) => getOption(contract))
      .filter((contract) => contract.group)
      .sort((a, b) => a.group.localeCompare(b.group));
    setOptions(options);
  }, [contracts]);

  return options;
};

export default useGetContractsOptions;

import React, { useEffect, useState } from 'react';
import {
  BirdzDialog,
  CheckboxField,
  InputField,
  useDialog
} from '@applications-terrains/birdz-react-library';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFetchContext } from '../../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';
import { Formik, Form } from 'formik';
import { Box, Button } from '@mui/material';
import axios from 'axios';
import { Support, SupportId } from './SupportsList';
import PhoneField from '../../../Tools/Fields/PhoneField';

const SupportForm = ({
  supportId,
  setSupportId
}: {
  supportId: SupportId;
  setSupportId: (value: SupportId) => void;
}) => {
  const { id } = useParams();
  const endpoint = '/api/boi/breader/supports/';
  const { toggleNeedToFetch } = useFetchContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [support, setSupport] = useState<Support | null>(null);
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = () => {
    setSupportId(null);
  };

  useEffect(() => {
    if (supportId && supportId !== 'add' && !support) {
      setRequestStatus('pending');
      setIsFetching(true);
      axios.get(endpoint + supportId).then((response) => {
        setSupport(response.data);
        setTimeout(() => {
          setRequestStatus(null);
          setIsFetching(false);
        }, 400);
      });
    }
  }, [endpoint, supportId]);

  const handleSubmit = (values: Support) => {
    setRequestStatus('pending');
    const { password, id: suppId, client, ...rest } = values;
    const formattedObj: { [key: string]: string | number | boolean } = {
      ...rest,
      client: typeof client === 'string' ? parseInt(client) : client
    };
    if (suppId && suppId !== 'add') {
      formattedObj.id = typeof suppId === 'string' ? parseInt(suppId) : suppId;
    }
    if (suppId === 'add') {
      formattedObj.password = password;
    }

    const method = suppId === 'add' ? 'post' : 'put';
    const url = `${endpoint}${suppId === 'add' ? '' : +suppId}`;

    closeDialog();
    return axios[method](url, formattedObj)
      .then(() => {
        setRequestStatus('success');
        setTimeout(() => {
          handleClose();
          toggleNeedToFetch(true);
        }, 1500);
      })
      .catch((err) => {
        setRequestStatus('error');
        const errorMessage =
          'error' in err.response.data &&
          err.response.data.error.includes('Maximum number of allowed users already reached')
            ? "Nombre maximum d'utilisateurs atteint"
            : err.response.data.error === ''
              ? "l'email renseigné est déjà utilisé. Veuillez en saisir un autre."
              : `Une erreur est survenue lors de la ${suppId === 'add' ? 'création' : 'modification'} de l'export`;
        setErrorMessage(errorMessage);
      });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <ModalWithLoader
      openModal={true}
      onClose={() => {
        handleClose();
      }}
      status={requestStatus}
      timeout={requestStatus === 'error' ? 3000 : 1500}
      customErrorMessage={errorMessage}
      action={`${isFetching ? 'Recherche' : supportId === 'add' ? 'Création' : 'Modification'} du gestionnaire`}
    >
      {(supportId === 'add' || support) && (
        <Formik
          validationSchema={Yup.object({
            name: Yup.string().required('Vous devez renseigner les nom et prénom'),
            email: Yup.string()
              .email('Vous devez renseigner un email valide')
              .required('Vous devez renseigner un email'),
            phone: Yup.string()
              .required('Vous devez renseigner un numéro de téléphone')
              .matches(phoneRegExp, 'Vous devez renseigner un numéro de téléphone valide')
              .min(10, 'Numéro de téléphone trop court')
              .max(11, 'Numéro de téléphone trop long'),
            password:
              supportId === 'add'
                ? Yup.string().required('Vous devez renseigner un mot de passe')
                : Yup.string(),
            main_contact: Yup.bool(),
            is_active: Yup.bool()
          })}
          initialValues={
            support || {
              name: '',
              email: '',
              phone: '',
              password: '',
              main_contact: false,
              is_active: false
            }
          }
          onSubmit={(values) => {
            confirmDialog({
              title: `Confirmer ${supportId ? 'les modifications' : 'la création'}`,
              content: 'Souhaitez-vous confirmer vos saisies ?',
              onValidate: () =>
                handleSubmit({ ...values, client: id as string | number, id: supportId || '' }),
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
        >
          {({ isValid, dirty }) => {
            return (
              <Form>
                <Box sx={{ p: 5 }}>
                  <Box sx={{ height: 80 }}>
                    <InputField name="name" type="text" label="Nom" size="small" fullWidth />
                  </Box>
                  <Box sx={{ height: 80 }}>
                    <PhoneField />
                  </Box>
                  <Box sx={{ height: 80 }}>
                    <InputField name="email" type="email" label="Email" size="small" fullWidth />
                  </Box>
                  <Box sx={{ height: 70 }}>
                    <InputField
                      name="password"
                      type="password"
                      label="Mot de passe"
                      size="small"
                      fullWidth
                    />
                  </Box>
                  <CheckboxField id="mainContact" name="main_contact" label="Contact principal" />
                  <CheckboxField id="is_active" name="is_active" label="Actif" />
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ m: 2 }}
                    disabled={!dirty || !isValid}
                  >
                    Enregistrer
                  </Button>

                  <Button
                    className="btn-space"
                    onClick={() => {
                      confirmDialog({
                        title: 'Annuler les saisies',
                        content: 'Souhaitez-vous annuler vos saisies ?',
                        onValidate: () => {
                          handleClose();
                        },
                        onCancel: () => {
                          handleClose();
                        },
                        okButtonLabel: 'Oui',
                        cancelButtonLabel: 'Non'
                      });
                    }}
                  >
                    Annuler
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
      <BirdzDialog options={dialogOptions} />
    </ModalWithLoader>
  );
};

export default SupportForm;

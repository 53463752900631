import React, { useState, ReactNode, useEffect } from 'react';
import { BirdzModal, BirdzModalContent } from '@applications-terrains/birdz-react-library';
import { Backdrop, Box, Button, CircularProgress, Fade } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Warning from '@mui/icons-material/Warning';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import styled, { keyframes } from 'styled-components';
import { red, blue, orange } from '@mui/material/colors';
import LinearProgress from '@mui/material/LinearProgress';
import { removeAccents } from '../../../utils';

export type RequestStatus = 'success' | 'pending' | 'error' | 'successWithNoResults' | null;

type ModalWithLoaderType = {
  children?: ReactNode;
  onClose?: () => void;
  openModal: boolean;
  action?: string;
  status: RequestStatus;
  setStatus?: (status: RequestStatus) => void;
  customErrorMessage?: string;
  timeout?: number;
  onSuccess?: () => void;
};

const ModalWithLoader = ({
  children,
  onClose,
  status,
  setStatus,
  openModal,
  action = 'Enregistrement',
  customErrorMessage,
  timeout = 1500,
  onSuccess
}: ModalWithLoaderType) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (openModal) {
      setIsOpen(true);
    } else {
      status !== 'successWithNoResults' &&
        setTimeout(
          () => {
            setIsOpen(false);
            setStatus && setStatus(null);
            status === 'success' && onSuccess && onSuccess();
          },
          status === 'success' || status === 'error' ? timeout : 300
        );
    }
  }, [openModal, status, onSuccess]);

  const closeFunc =
    status !== 'pending'
      ? onClose
      : () => {
          //do nothing
        };

  return (
    <BirdzModal
      open={isOpen}
      closeAfterTransition
      onClose={closeFunc}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 300
        }
      }}
    >
      <Fade in={isOpen}>
        <Box>
          <BirdzModalContent>
            <Box sx={{ minHeight: 365 }}>
              {children}
              <Overlay
                timeout={timeout}
                action={action}
                status={status}
                isOpen={openModal}
                onClose={() => {
                  setIsOpen(false);
                  setStatus && setStatus(null);
                  closeFunc && closeFunc();
                }}
                customErrorMessage={customErrorMessage}
              />
            </Box>
          </BirdzModalContent>
        </Box>
      </Fade>
    </BirdzModal>
  );
};

export default ModalWithLoader;

const blueColor = blue[300];
const redColor = red[400];
const orangeColor = orange[300];

const Overlay = ({
  action,
  status,
  isOpen,
  onClose,
  customErrorMessage,
  timeout
}: {
  action: string;
  status: RequestStatus;
  isOpen: boolean;
  onClose: () => void;
  customErrorMessage?: string;
  timeout: number;
}) => {
  const getFeminine = () => {
    const feminineWords = ['creation', 'modification', 'suppression', 'edition'];
    return action
      .split(' ')
      .map((word) => (feminineWords.includes(removeAccents(word.toLowerCase())) ? 'e' : ''))
      .filter((el) => el)
      .join();
  };

  const data = {
    success: {
      message: action + ` réalisé${getFeminine()} avec succès.`,
      icon: <SuccessIcon />
    },
    successWithNoResults: {
      message: "La requête n'a donné aucun résultat. Merci de saisir des critères différents.",
      icon: <WarningIcon />
    },
    pending: {
      message: action + ' en cours... Merci de patienter.',
      icon: <CircularProgress sx={{ mb: 3, fontSize: '1.2rem' }} />
    },
    error: {
      message: customErrorMessage || 'Une erreur est survenue. Merci de réessayer.',
      icon: <ErrorIcon />
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  const [tmpStatus, setTmpStatus] = useState<RequestStatus>(null);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const handleClose = () => {
    setTmpStatus(null);
    setIsVisible(false);
    setShowProgressBar(false);
  };

  useEffect(() => {
    if (status === null) {
      handleClose();
    }
    if (status !== null) {
      setIsVisible(true);
      setTmpStatus(status);
    }
    if (status === 'success') setShowProgressBar(true);
    if (status !== 'successWithNoResults' && (!isOpen || status === 'error')) {
      setShowProgressBar(true);
      setTimeout(() => {
        handleClose();
      }, timeout + 200);
    }
  }, [isOpen, status]);

  return tmpStatus !== null ? (
    <Box
      sx={{
        color: 'rgba(0, 0, 0, 0.54)',
        fontWeight: 300,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out'
      }}
    >
      <div className="d-flex flex-column align-items-center">
        {data[status as keyof typeof data]?.icon}
        {data[status as keyof typeof data]?.message}
        {showProgressBar && (
          <LinearDeterminate
            color={
              status === 'error' ? 'red' : status === 'successWithNoResults' ? 'orange' : 'blue'
            }
            timeout={timeout}
          />
        )}
        {status === 'successWithNoResults' && (
          <Button
            sx={{ mt: 3, color: orangeColor }}
            color="warning"
            onClick={() => {
              onClose();
              setTmpStatus(null);
              setIsVisible(false);
            }}
          >
            modifier ma recherche
          </Button>
        )}
      </div>
    </Box>
  ) : (
    <></>
  );
};

const expandWidth = keyframes`
0% { width: 0px; }
100% {width: 55px; }
`;
const StyledSuccessIcon = styled.div`
  overflow: hidden;
  width: 55px;
  animation-name: ${expandWidth};
  animation-duration: 0.7s;
`;

const SuccessIcon = () => {
  return (
    <Box sx={{ width: '55px', height: '55px', mb: 3 }}>
      <StyledSuccessIcon>
        <CheckIcon sx={{ fontSize: '55px', color: blueColor }} />
      </StyledSuccessIcon>
    </Box>
  );
};

const expandHeight = keyframes`
  0% { height: 0px; }
  100% {height: 55px; }
 `;

const StyledErrorIcon = styled.div`
  overflow: hidden;
  width: 55px;
  animation-name: ${expandHeight};
  animation-duration: 0.2s;
`;

const ErrorIcon = () => {
  return (
    <Box sx={{ width: '55px', height: '55px', mb: 3 }}>
      <StyledErrorIcon>
        <PriorityHighIcon sx={{ fontSize: '55px', color: redColor }} />
      </StyledErrorIcon>
    </Box>
  );
};

const WarningIcon = () => {
  return (
    <Box sx={{ width: '55px', height: '55px', mb: 3 }}>
      <StyledErrorIcon>
        <Warning sx={{ fontSize: '55px', color: orangeColor }} />
      </StyledErrorIcon>
    </Box>
  );
};

const LinearDeterminate = ({
  color,
  timeout
}: {
  color: 'red' | 'blue' | 'orange';
  timeout: number;
}) => {
  const [progress, setProgress] = useState<number>(0);

  const colors = {
    red: {
      secondary: red[100],
      main: redColor
    },
    blue: {
      secondary: blue[100],
      main: blueColor
    },
    orange: {
      secondary: orange[100],
      main: orangeColor
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return oldProgress < 100 ? oldProgress + timeout / timeout : 100;
      });
    }, timeout / 180);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', mt: 1 }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 4,
          borderRadius: 2,
          backgroundColor: colors[color].secondary,
          '& .MuiLinearProgress-bar': {
            borderRadius: 2,
            backgroundColor: colors[color].main
          }
        }}
      />
    </Box>
  );
};

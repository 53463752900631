import React from 'react';
import { Contract } from './ContractsDropdown';
import { Box, Chip } from '@mui/material';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import PlaceIcon from '@mui/icons-material/Place';

const OptionLabel = ({ contract }: { contract: Contract }) => (
  <Box className="d-flex align-items-center">
    <Box sx={{ minWidth: '150px' }}>
      <Chip
        label={
          <div className="d-flex align-items-center">
            <Grid3x3Icon sx={{ mr: 1 }} fontSize="small" /> {contract.contract_management_code}
          </div>
        }
      />
    </Box>
    <Box className="d-flex align-items-center">
      <PlaceIcon sx={{ mr: 1 }} fontSize="small" /> {contract.contract_ve}
    </Box>
  </Box>
);

export default OptionLabel;

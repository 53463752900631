import React from 'react';
import { Grid, Skeleton } from '@mui/material';
import ClearableSelect from '../../../../../Tools/ClearableSelect';
import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useTourFormContext } from '../context/tourFormContext';

type Reader = {
  name: string;
  id: number;
};

const ReaderStep = () => {
  const [readers, setReaders] = useState<Reader[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { tourData, setTourData } = useTourFormContext();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/boe/breader/readers/`)
      .then((response: AxiosResponse) => {
        const readers = response.data.results;
        setReaders(readers);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  if (readers.length === 0) return null;
  return (
    <Grid container className="d-flex" alignItems={'center'} sx={{ mb: 2 }}>
      <Grid item xs={2}>
        Releveur
      </Grid>
      <Grid item xs={10}>
        {isLoading ? (
          <Skeleton variant="rounded" width={'100%'} height={40} />
        ) : (
          <ClearableSelect
            defaultValue={tourData.readerStep}
            options={readers
              .map((reader) => ({
                label: reader.name,
                value: reader.id
              }))
              .sort((a, b) => a.label.localeCompare(b.label))}
            onChange={(reader) => {
              setTourData({
                ...tourData,
                readerStep: reader
              });
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ReaderStep;

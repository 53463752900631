import React, { ReactNode, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppPaper, BirdzDialog, useDialog } from '@applications-terrains/birdz-react-library';
import { useNavigate } from 'react-router-dom';
import DevicesStep from './DevicesStep/DevicesStep';
import { StepType } from './types';
import { TourFormContextProvider, useTourFormContext } from './context/tourFormContext';
import PatrimonyStep from './PatrimonyStep/PatrimonyStep';
import ReaderStep from './ReaderStep/ReaderStep';
import ConfirmModalContent from './ConfirmModalContent';
import axios from 'axios';
import ModalWithLoader, { RequestStatus } from '../../../../Tools/ModalWithLoader/ModalWithLoader';

type TourProps = {
  tour?: any;
};

const TourForm = ({ tour }: TourProps) => {
  const navigate = useNavigate();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const { tourData, setTourData, currentStep, setCurrentStep } = useTourFormContext();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  useEffect(() => {
    tour && setTourData(tour);
  }, []);

  const handleClose = () => {
    closeDialog();
  };

  const handleCurrentStep = (step: StepType) => setCurrentStep(currentStep === step ? null : step);

  return (
    <AppPaper>
      <Box sx={{ mt: 3 }}>
        <TextField
          id="outlined-basic"
          label="Nom de la tournée *"
          variant="outlined"
          className="w-100"
          value={tourData && tourData.name}
          onChange={(e) => setTourData({ ...tourData, name: e.target.value })}
        />
      </Box>
      <Step
        disabled={!tourData.name}
        expanded={tourData.name.length > 0 && currentStep === 'patrimonyStep'}
        setExpanded={() => handleCurrentStep('patrimonyStep')}
        title={'Import patrimoine'}
      >
        <PatrimonyStep />
      </Step>

      <Step
        disabled={!tourData.name || !tourData.patrimonyStep}
        expanded={tourData.name.length > 0 && currentStep === 'devicesStep'}
        setExpanded={() => handleCurrentStep('devicesStep')}
        title={'Création de la tournée'}
      >
        <DevicesStep />
      </Step>
      <Step
        disabled={!tourData.name || !tourData.devicesStep}
        expanded={tourData.name.length > 0 && currentStep === 'readerStep'}
        setExpanded={() => handleCurrentStep('readerStep')}
        title={'Attribution par releveur'}
      >
        <ReaderStep />
      </Step>

      <Box sx={{ textAlign: 'end' }}>
        <Button
          className="btn-space"
          onClick={() => {
            confirmDialog({
              title: 'Annuler les saisies',
              content: 'Souhaitez-vous annuler vos saisies ?',
              onValidate: () => {
                handleClose();
                navigate(-1);
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
        >
          Annuler
        </Button>
        <Button
          onClick={() => {
            confirmDialog({
              title: `Confirmer la création de la tournée`,
              content: <ConfirmModalContent />,
              onValidate: () => {
                setRequestStatus('pending');
                const payload = {
                  name: tourData.name,
                  reader: tourData.readerStep?.value,
                  devices: tourData?.devicesStep?.map((device) => {
                    const { MODULE, device_type, frame_type, ...rest } = device;
                    return {
                      device_id: MODULE,
                      device_type,
                      frame_type,
                      data: { ...rest, MODULE }
                    };
                  })
                };
                axios
                  .post('/api/boe/breader/tours/', payload)
                  .then(() => {
                    setRequestStatus('success');
                    closeDialog();
                    setTimeout(() => {
                      navigate(-1);
                    }, 2000);
                  })
                  .catch(() => {
                    setRequestStatus('error');
                    closeDialog();
                  });
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
          variant="contained"
          sx={{ m: 2 }}
          disabled={
            !tourData.name ||
            !tourData.devicesStep ||
            !tourData.readerStep ||
            requestStatus === 'pending'
          }
        >
          {requestStatus === 'pending' && (
            <CircularProgress size={18} thickness={5} sx={{ mr: 1 }} />
          )}{' '}
          Enregistrer
        </Button>
      </Box>

      <BirdzDialog options={dialogOptions} />
      <ModalWithLoader
        openModal={requestStatus === 'pending'}
        onClose={() => {
          handleClose();
          setRequestStatus(null);
        }}
        action="Création de la tournée"
        status={requestStatus}
        setStatus={setRequestStatus}
      />
    </AppPaper>
  );
};

const Step = ({
  title,
  children,
  expanded,
  setExpanded,
  disabled
}: {
  expanded: boolean;
  setExpanded: () => void;
  title: string;
  children: ReactNode;
  disabled: boolean;
}) => {
  return (
    <Accordion
      disabled={disabled}
      square={false}
      variant="outlined"
      sx={{ mt: 2, borderRadius: '4px' }}
      expanded={expanded}
      disableGutters={true}
    >
      <AccordionSummary
        onClick={setExpanded}
        expandIcon={<ExpandMoreIcon />}
        sx={{ mb: 0, border: 'none' }}
      >
        <h3>{title}</h3>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const TourFormBOE = ({ tour }: any) => (
  <TourFormContextProvider>
    <TourForm tour={tour} />
  </TourFormContextProvider>
);

export default TourFormBOE;

import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { LatLngExpression } from 'leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { TourDevice } from '../types';

const TourMap = ({
  devices,
  onSelected
}: {
  devices: TourDevice[];
  onSelected: (value: TourDevice[]) => void;
}) => {
  const defaultZoom = 14;
  const zoomValues: { [key: number]: number } = {
    10: 60,
    11: 50,
    12: 40,
    13: 30,
    14: 20,
    15: 15,
    16: 10,
    17: 5
  };
  const getVisiblePoints = (visiblePoints: TourDevice[], zoom: number) =>
    visiblePoints.filter((_, index) => index % zoomValues[zoom < 10 ? 10 : zoom] === 0);

  const [filteredDevices, setFilteredDevices] = useState<TourDevice[]>(
    getVisiblePoints(devices, defaultZoom)
  );

  // init mounted state on component mount to re-trigger map ref (otherwise stays to null on tab change)
  const [mounted, setMounted] = useState(false);
  const mapRef = useRef<L.Map>(null);

  useEffect(() => {
    if (mounted) {
      const map = mapRef.current;
      if (map) {
        const handleZoomOrMove = () => {
          const zoom = map.getZoom();
          const bounds = map.getBounds();
          let visiblePoints: TourDevice[] = devices.filter((device) => {
            return (
              device?.COORD_Y &&
              device?.COORD_X &&
              bounds.contains([parseFloat(device.COORD_Y), parseFloat(device.COORD_X)])
            );
          });

          // Modifier le nombre de points visibles selon le niveau de zoom pour éviter de ralentir l'interface
          if (devices?.length > 1000 && zoom < 18) {
            visiblePoints = getVisiblePoints(visiblePoints, zoom);
          }

          setFilteredDevices(visiblePoints);
        };

        // Appliquer le filtrage lors du montage de la carte
        handleZoomOrMove();

        // Appliquer le filtrage au zoom et au déplacement de la carte
        map.on('zoomend', handleZoomOrMove);
        map.on('moveend', handleZoomOrMove);

        // Nettoyer les événements lorsque le composant est démonté
        return () => {
          map.off('zoomend', handleZoomOrMove);
          map.off('moveend', handleZoomOrMove);
        };
      }
    } else setMounted(true);
  }, [devices, mounted]);

  // Calculer le centre de la carte
  const avgX =
    filteredDevices.reduce((sum, device) => sum + parseFloat(device.COORD_X), 0) /
    filteredDevices.length;
  const avgY =
    filteredDevices.reduce((sum, device) => sum + parseFloat(device.COORD_Y), 0) /
    filteredDevices.length;
  const center: LatLngExpression = { lat: avgY, lng: avgX };

  // Configurer l'icône par défaut des markers
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  // Référence à la dernière forme créée
  let currentLayer: L.Layer | null = null;

  const handleDraw = (
    e: L.DrawEvents.Created | L.DrawEvents.Edited | L.DrawEvents.Deleted,
    action: 'create' | 'edit' | 'delete'
  ) => {
    if (action === 'delete') {
      onSelected([]);
      currentLayer = null;
    } else {
      const processLayer = (layer: L.Layer & { getBounds?: () => L.LatLngBounds }) => {
        const bounds = layer.getBounds?.();
        if (bounds) {
          const selectedDevices = devices.filter((device) =>
            bounds.contains([parseFloat(device.COORD_Y), parseFloat(device.COORD_X)])
          );
          onSelected(selectedDevices);
        }
      };

      if (action === 'create') {
        const layer = (e as L.DrawEvents.Created).layer as L.Layer & {
          getBounds?: () => L.LatLngBounds;
        };

        // Supprimer la forme existante si elle est présente
        if (currentLayer) {
          mapRef.current?.removeLayer(currentLayer);
        }

        processLayer(layer);
        currentLayer = layer; // Mettre à jour la référence à la nouvelle forme créée
      } else if (action === 'edit') {
        const editedLayers = (e as L.DrawEvents.Edited).layers as L.LayerGroup;
        editedLayers.eachLayer((layer: L.Layer & { getBounds?: () => L.LatLngBounds }) => {
          processLayer(layer);
        });
      }
    }
  };

  return (
    <MapContainer
      center={center}
      zoom={defaultZoom}
      style={{ height: '550px' }}
      scrollWheelZoom={false}
      ref={mapRef}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {filteredDevices.map((device) => (
        <Marker
          key={device.MODULE}
          position={[parseFloat(device.COORD_Y), parseFloat(device.COORD_X)]}
        />
      ))}
      <FeatureGroup>
        <EditControl
          position="topright"
          onCreated={(e) => handleDraw(e, 'create')}
          onEdited={(e) => handleDraw(e, 'edit')}
          onDeleted={(e) => handleDraw(e, 'delete')}
          draw={{
            rectangle: true,
            polyline: true,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: false
          }}
        />
      </FeatureGroup>
    </MapContainer>
  );
};

export default TourMap;

import { Box, Button, IconButton, Skeleton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';

import {
  BirdzModal,
  BirdzModalTitle,
  BirdzModalContent,
  BirdzModalActions,
  useNotif,
  BirdzNotif
} from '@applications-terrains/birdz-react-library';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import axios from 'axios';

type Option = { label: string; value: number };
type Status = { Label: string; Id: number };

const InterventionStatusEditor = ({
  currentStatus,
  handleRefresh
}: {
  currentStatus: string;
  handleRefresh: (notifObj: object) => void;
}) => {
  const { id } = useParams();
  const { notif, notifOptions } = useNotif();

  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<Option | undefined>(undefined);
  const [statuses, setStatuses] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShowModal(false);

  const getStatuses = () => {
    setIsLoading(true);
    axios
      .get(`/api/exocet/bconnect-interventions-status/`)
      .then(({ data }) => {
        setStatuses(data.map((el: Status) => ({ label: el.Label, value: el.Id })));
        const status = data.find((el: Status) => el.Label === currentStatus);
        setStatus({ label: status.Label, value: status.Id });
        setIsLoading(false);
      })
      .catch((err) => {
        notif({
          type: 'error',
          content: `Une erreur est survenue lors de la récupération des status (${JSON.stringify(err)})`
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (showModal) {
      statuses.length === 0 && getStatuses();
    } else {
      statuses.length > 0 && setStatuses([]);
      status && setStatus(undefined);
    }
  }, [showModal, statuses, status]);

  return (
    <>
      <IconButton onClick={() => setShowModal(true)}>
        <EditIcon />
      </IconButton>
      <BirdzModal open={showModal} onClose={handleClose}>
        <BirdzModalTitle>Modifier le statut</BirdzModalTitle>
        <BirdzModalContent>
          <Box sx={{ p: 2, pb: 25 }}>
            {isLoading && !status ? (
              <Skeleton variant="rounded" width={'100%'} height={40} />
            ) : (
              <Select
                options={statuses}
                onChange={(val) => val && setStatus(val as Option)}
                value={status}
                placeholder="Choisissez un statut"
              />
            )}
          </Box>
        </BirdzModalContent>
        <BirdzModalActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              axios
                .put(`/api/exocet/unfinished-interventions/${id}/`, {
                  status: status?.value
                })
                .then(
                  () => {
                    handleRefresh({
                      type: 'success',
                      content: 'La statut a été modifié avec succès'
                    });
                  },
                  (error) => {
                    let errors = error?.response?.data ?? [];
                    if (errors) {
                      errors = Object.keys(errors).map(
                        (fieldError) => fieldError + ': ' + errors[fieldError].join(',')
                      );
                    }
                    notif({
                      type: 'error',
                      content: (
                        <>
                          Une erreur est survenue lors de la modification
                          <br />
                          <ul>
                            {errors.map((error: string) => (
                              <li key={error}>{error}</li>
                            ))}
                          </ul>
                        </>
                      )
                    });
                  }
                );
              handleClose();
            }}
          >
            Modifier
          </Button>
        </BirdzModalActions>
      </BirdzModal>
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default InterventionStatusEditor;

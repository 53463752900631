import { InputField } from '@applications-terrains/birdz-react-library';
import { styled } from '@mui/material';
import React from 'react';

type PhoneFieldType = {
  name?: string;
  label?: string;
  size?: string | number;
  sx?: { [key: string]: string | number };
};

const StyledInput = styled(InputField)(() => ({
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none'
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  }
}));

const PhoneField = ({
  name = 'phone',
  label = 'Téléphone',
  size = 'small',
  sx
}: PhoneFieldType) => {
  return <StyledInput type="number" name={name} label={label} size={size} sx={sx} fullWidth />;
};

export default PhoneField;

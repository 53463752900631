import { useGetErps } from '../../../hooks/datarefs';
import { Erp } from './ErpForm';
import { getERPNameAndVersion } from './utils';

type ErpOption = {
  label: string;
  value: number;
};
const useErpOptions = () => {
  const { data: erps } = useGetErps();

  return (
    erps
      ?.sort((a, b) => {
        const nameComparison = a.name.localeCompare(b.name);
        if (nameComparison !== 0) {
          return nameComparison; // Sort by name
        }
        // If names are the same, sort by version
        return a.version - b.version;
      })
      .map(
        (erp: Erp) =>
          ({
            label: getERPNameAndVersion(erp),
            value: erp.id
          }) as ErpOption
      ) || []
  );
};

export default useErpOptions;

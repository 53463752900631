import React, { useState } from 'react';
import { AppPaper, SearchField, SearchForm } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import ClearableSelect from '../../Tools/ClearableSelect';
import { useGetClients, useGetExportFormats, useGetModuleFamilies } from '../../../hooks/datarefs';
import { translations } from './JobForm';
import { capitalizeFirstLetter } from '../../../utils';
import useErpOptions from '../Erps/useErpOptions';

const JobSearchForm = ({
  setFilterString,
  endpoint
}: {
  endpoint: string;
  setFilterString: (value: string) => void;
}) => {
  const { data: moduleFamilies } = useGetModuleFamilies();
  const { data: clients } = useGetClients();
  const { data: exportFormats } = useGetExportFormats();
  const ErpOptions = useErpOptions();

  const [filters, setFilters] = useState<{ field: string; value: string }[]>([]);

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Nom',
      options: {
        identifier: 'name',
        source: endpoint,
        searchIsMulti: false
      }
    },
    {
      name: 'erp',
      label: 'ERP',
      type: 'select',
      options: {
        values: ErpOptions
      }
    }
  ];

  const setFilter = (field: string, option: { label: string; value: string | number } | null) =>
    setFilters((filters) => [
      ...filters.filter((el) => el.field !== field),
      { field, value: `&${field}=${option?.value.toString() || ''}` }
    ]);

  const displayField = (
    field: string,
    options: { label: string; value: string | number }[],
    index: number
  ) => (
    <Grid
      item
      className="d-flex"
      alignItems={'center'}
      xs={6}
      sx={{ pt: '16px', pl: '16px' }}
      key={field + index}
    >
      <Grid item xs={3}>
        {capitalizeFirstLetter(translations[field as keyof typeof translations])}
      </Grid>
      <Grid item xs={9}>
        <ClearableSelect onChange={(option) => setFilter(field, option)} options={options} />
      </Grid>
    </Grid>
  );

  const options = {
    module_families: moduleFamilies?.map((moduleFamily) => {
      return { value: moduleFamily.Name, label: moduleFamily.Name };
    }),
    client: clients?.map((client) => {
      return { value: client.id, label: client.name };
    }),
    export_format: exportFormats?.map((format) => {
      return { value: format.id, label: format.name };
    }),
    active: [
      { value: true, label: 'Actif' },
      { value: false, label: 'Inactif' }
    ]
  };

  return (
    <AppPaper>
      <Grid container sx={{ mb: '16px', ml: '-16px', width: 'calc(100% + 16px)' }}>
        {Object.entries(options).map(([name, options], index) =>
          displayField(name, options || [], index)
        )}
      </Grid>

      <SearchForm
        fields={searchFields}
        onSubmit={(filterString: string) => {
          setFilterString(filterString + filters.map((el) => el.value).join(''));
        }}
      />
    </AppPaper>
  );
};

export default JobSearchForm;
